@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .v2_termpri_all {
    width: 70%;
    margin: 2% auto;
  }
  .v2_termpri_all {
    width: 70%;
    margin: 8% auto 20% auto;
  }

  .v2_termpri_all_title {
    font-weight: bold;
    font-size: 30px;
    margin-top: 5%;
    margin-bottom: 1%;
  }

  .v2_termpri_all p,
  .v2_termpri_all div p {
    line-height: 150%;
    font-size: 14px;
  }

  .v2_termpri_1 {
    font-weight: bold;
    font-size: 20px;
    margin-top: 3%;
  }

  .v2_termpri_2 {
    margin-top: 1%;
    font-weight: bold;
  }

  .v2_termpri_3 {
    margin-left: 1%;
  }

  .v2_termpri_4 {
    margin-left: 2%;
  }

  .v2_termpri_5 {
    margin-top: 1%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 30000px) {
  .v2_termpri_all {
    width: 70%;
    margin: 2% auto;
  }

  .v2_termpri_all_title {
    font-weight: bold;
    font-size: 30px;
    margin-top: 5%;
    margin-bottom: 1%;
  }

  .v2_termpri_all p,
  .v2_termpri_all div p {
    line-height: 150%;
    font-size: 14px;
  }

  .v2_termpri_1 {
    font-weight: bold;
    font-size: 20px;
    margin-top: 3%;
  }

  .v2_termpri_2 {
    margin-top: 1%;
    font-weight: bold;
  }

  .v2_termpri_3 {
    margin-left: 1%;
  }

  .v2_termpri_4 {
    margin-left: 2%;
  }

  .v2_termpri_5 {
    margin-top: 1%;
  }
}

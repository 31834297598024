@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .logintext {
    font-size: 20px;
    margin: 15px auto;
    font-weight: bold;
    display: flex;
    justify-content: center;
    width: 90%;
  }
  .loginarea {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }
  .loginbtn {
    padding: 10px;
    background-color: $SubColor;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    width: 70%;
    margin: 15px 0;
  }

  .loginbtn.loader-visible {
    background-color: transparent; /* 로더가 나타날 때 투명한 배경색으로 설정 */
  }
  .loginfind {
    float: left;
    display: flex;
    margin: 15px 0;
  }

  .loginfind_id {
    margin-right: 15px;
    font-size: 12px;
    cursor: pointer;
    margin-right: 15px;
  }

  .loginfind_pw {
    margin-left: 15px;
    font-size: 12px;
    cursor: pointer;
  }
  .loginbox input {
    font-size: 16px;
    width: 90%;
    margin-bottom: 3%;
    font-weight: 300;
    outline: none;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .logintext {
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    width: 90%;
  }
  .loginarea {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }
  .loginbtn {
    padding: 10px;
    background-color: $SubColor;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    width: 90%;
    margin: 15px 0;
  }

  .loginbtn.loader-visible {
    background-color: transparent; /* 로더가 나타날 때 투명한 배경색으로 설정 */
  }

  .loginfind {
    float: left;
    display: flex;
    margin: 15px 0;
  }

  .loginfind_id {
    margin-right: 15px;
    font-size: 12px;
    cursor: pointer;
    margin-right: 15px;
  }

  .loginfind_pw {
    margin-left: 15px;
    font-size: 12px;
    cursor: pointer;
  }
  .loginbox input {
    width: 90%;
    margin-bottom: 3%;
    font-weight: 300;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .logintext {
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: bold;
    display: flex;
    justify-content: left;
    width: 80%;
  }
  .loginarea {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
  }
  .loginbtn {
    padding: 10px;
    background-color: $SubColor;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    width: 270px;
    margin: 15px 0;
  }

  .loginbtn.loader-visible {
    background-color: transparent; /* 로더가 나타날 때 투명한 배경색으로 설정 */
  }
  .loginfind {
    float: left;
    display: flex;
    margin: 15px 0;
  }

  .loginfind_id {
    margin-right: 15px;
    font-size: 12px;
    cursor: pointer;
    margin-right: 15px;
  }

  .loginfind_pw {
    margin-left: 15px;
    font-size: 12px;
    cursor: pointer;
  }
  .loginbox input {
    width: 270px;
    margin-bottom: 3%;
    font-weight: 300;
  }
}

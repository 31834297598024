@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .memory_subcategory_sec {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 auto;
  }

  .memory_sub_button {
    width: 100%;
    height: auto;
    cursor: pointer;
    background-color: transparent;
    font-size: 16px;
    height: 25px;
    color: gray;
    border: none;
  }

  .memory_sub_button.clicked {
    border-bottom: 3px solid $MainColor;
    color: black;
    font-weight: bold;
  }
  .memory_img_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .memory_img_modal_content {
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    background-color: transparent;
  }

  .memory_img_modal_content img {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }
  .memory_sec {
    width: 100%;
    margin: 0 auto;
  }
  .memory_nodata {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: gray;
  }

  .memory_my {
    border: none;
    border-radius: 15px;
    padding: 5% 3%;
    width: 100%;
    margin: 2% auto;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  .memory_left {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .memory_left_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .memory_left_title_p {
    font-weight: 14px;
  }
  .memory_left_sub_title {
    font-weight: 16px;
    margin-top: 3%;
    font-weight: bold;
  }

  .memory_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
    justify-content: right;
  }
  .memory_right p {
    margin-right: 5%;
    width: 70%;
    text-align: right;
    font-size: 12px;
    color: gray;
  }
  .memory_right img {
    width: 10px;
    height: 10px;
  }

  .memory_right button {
    padding: 5% 15%;
    background-color: transparent;
    color: black;
    font-size: 10px;
    border-radius: 5px;
    border: 1px solid $MainColor;
    width: 64px;
    display: none;
  }
  .memory_showcontent_img {
    width: 100%;
    height: 200px;
    position: relative;
    margin: 0 auto;
    background-color: #f7f7f7;
    border-radius: 15px;
  }
  .memory_showcontent_img p {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 10px;
    color: gray;
  }
  .memory_showcontent_img img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 70%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
  .memory_showcontent_p {
    margin-top: 5%;
  }
  .memory_showcontent_p p:nth-child(1) {
    margin-bottom: 1%;
    font-size: 14px;
    font-weight: bold;
  }
  .memory_showcontent_p p:nth-child(2) {
    font-size: 12px;
    margin-bottom: 5%;
  }
  .memory_delete_sec {
    display: flex;
    width: 100%;
    justify-content: right;
  }
  .memory_delete_sec button {
    background-color: $SubColor;
    font-weight: bold;
    color: white;
    font-size: 12px;
    padding: 1% 4%;
    border: none;
    border-radius: 5px;
  }
  .rsm-geography {
    outline: none !important;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .memory_subcategory_sec {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 2% auto;
  }
  .memory_sub_button {
    width: 15%;
    height: auto;
    cursor: pointer;
    background-color: transparent;
    font-size: 16px;
    height: 25px;
    color: gray;
    border: none;
  }

  .memory_sub_button.clicked {
    border-bottom: 3px solid $MainColor;
    color: black;
    font-weight: bold;
  }
  .memory_img_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .memory_img_modal_content {
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    background-color: transparent;
  }

  .memory_img_modal_content img {
    max-width: 70%;
    max-height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }
  .memory_sec {
    width: 100%;
    margin: 0 auto;
  }
  .memory_nodata {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: gray;
  }
  .memory_my {
    border: none;
    border-radius: 5px;
    padding: 2%;
    width: 90%;
    margin: 0 auto;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .memory_left {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .memory_left_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .memory_left_title_p {
    font-weight: 14px;
  }
  .memory_left_sub_title {
    font-weight: 16px;
    margin-top: 3%;
    font-weight: bold;
  }

  .memory_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
    justify-content: right;
  }
  .memory_right p {
    margin-right: 5%;
    width: 70%;
    text-align: right;
    font-size: 12px;
    color: gray;
  }
  .memory_right img {
    width: 10px;
    height: 10px;
  }

  .memory_right button {
    padding: 5% 15%;
    background-color: transparent;
    color: black;
    font-size: 10px;
    border-radius: 5px;
    border: 1px solid $MainColor;
    width: 64px;
    display: none;
  }

  .memory_showcontent_img {
    width: 100%;
    height: 400px;
    position: relative;
    margin: 0 auto;
    background-color: #f7f7f7;
    border-radius: 15px;
  }
  .memory_showcontent_img p {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 12px;
    color: gray;
  }
  .memory_showcontent_img img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }

  .memory_showcontent_p {
    margin-top: 5%;
  }
  .memory_showcontent_p p:nth-child(1) {
    margin-bottom: 1%;
    font-size: 16px;
    font-weight: bold;
  }
  .memory_showcontent_p p:nth-child(2) {
    font-size: 14px;
    margin-bottom: 5%;
  }
  .memory_delete_sec {
    display: flex;
    width: 100%;
    justify-content: right;
  }
  .memory_delete_sec button {
    background-color: $SubColor;
    font-weight: bold;
    color: white;
    font-size: 14px;
    padding: 1% 4%;
    border: none;
    border-radius: 5px;
  }
  .rsm-geography {
    outline: none !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .memory_subcategory_sec {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 2% auto;
  }
  .memory_sub_button {
    width: 15%;
    height: auto;
    cursor: pointer;
    background-color: transparent;
    font-size: 16px;
    height: 25px;
    color: gray;
    border: none;
  }

  .memory_sub_button.clicked {
    border-bottom: 3px solid $MainColor;
    color: black;
    font-weight: bold;
  }
  .memory_img_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .memory_img_modal_content {
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    background-color: transparent;
  }

  .memory_img_modal_content img {
    max-width: 70%;
    max-height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }
  .memory_sec {
    width: 100%;
    margin: 0 auto;
  }
  .memory_nodata {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: gray;
  }
  .memory_my {
    border: none;
    border-radius: 5px;
    padding: 2%;
    width: 60%;
    margin: 5% auto 5% auto;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .memory_left {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .memory_left_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .memory_left_title_p {
    font-weight: 14px;
  }
  .memory_left_sub_title {
    font-weight: 16px;
    margin-top: 3%;
    font-weight: bold;
  }

  .memory_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
    justify-content: right;
  }
  .memory_right p {
    margin-right: 5%;
    width: 70%;
    text-align: right;
    font-size: 12px;
    color: gray;
  }
  .memory_right img {
    width: 10px;
    height: 10px;
  }

  .memory_right button {
    padding: 5% 15%;
    background-color: transparent;
    color: black;
    font-size: 10px;
    border-radius: 5px;
    border: 1px solid $MainColor;
    width: 64px;
    display: none;
  }

  .memory_showcontent_img {
    width: 100%;
    height: 400px;
    position: relative;
    margin: 0 auto;
    background-color: #f7f7f7;
    border-radius: 15px;
  }
  .memory_showcontent_img p {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 12px;
    color: gray;
  }
  .memory_showcontent_img img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }

  .memory_showcontent_p {
    margin-top: 5%;
  }
  .memory_showcontent_p p:nth-child(1) {
    margin-bottom: 1%;
    font-size: 16px;
    font-weight: bold;
  }
  .memory_showcontent_p p:nth-child(2) {
    font-size: 14px;
    margin-bottom: 5%;
  }
  .memory_delete_sec {
    display: flex;
    width: 100%;
    justify-content: right;
  }
  .memory_delete_sec button {
    background-color: $SubColor;
    font-weight: bold;
    color: white;
    font-size: 14px;
    padding: 1% 4%;
    border: none;
    border-radius: 5px;
  }
  .rsm-geography {
    outline: none !important;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .memory2_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .memory2_content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    overflow-y: scroll;
  }

  .memory2_register_sec {
    display: flex;
    flex-direction: column;
  }
  .memory2_register_sec_detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5%;
  }
  .memory2_register_sec_detail p {
    font-size: 14px;
    font-weight: bold;
  }
  .memory2_register_sec_detail input {
    width: 220px;
  }
  .memory2_register_sec_detail_date {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 220px;
    height: 25px;
  }
  .memory2_register_sec_detail_date input {
    width: 90px;
  }
  .memory2_register_sec_date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .memory2_register_sec_date input {
    font-size: 10px;
  }
  .memory2_source_register textarea {
    width: 100%;
    resize: none;
    border: 1px solid #bebebe;
    font-size: 16px;
    overflow-y: scroll;
    outline: none;
    border-radius: 5px;
    padding: 1%;
    overflow-y: scroll;
    height: 100px;
  }

  .memory2_content_color_title {
    margin: 3% auto;
    font-size: 14px;
    font-weight: bold;
  }
  .memory2_content_color_select p {
    text-align: center;
    margin: 7% auto;
    font-weight: bold;
  }

  .memory2_btnsec {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .memory2_btnsec button {
    background-color: $SubColor;
    border: none;
    border-radius: 5px;
    padding: 2% 3%;
    margin-right: 5%;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 14px;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .memory2_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .memory2_content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 40%;
    min-width: 450px;
    overflow-y: scroll;
  }

  .memory2_register_sec_detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5%;
  }
  .memory2_register_sec_detail p {
    font-size: 16px;
    font-weight: bold;
  }
  .memory2_register_sec_detail input {
    width: 200px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    font-size: 16px;
    color: gray;
    padding: 1%;
    outline: none;
  }
  .memory2_register_sec_detail_date {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 200px;
    height: 25px;
  }
  .memory2_register_sec_detail_date input {
    width: 160px;
  }
  .memory2_register_sec_date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .memory2_register_sec_date input {
    font-size: 10px;
  }

  .memory2_source_register textarea {
    width: 100%;
    resize: none;
    border: 1px solid #bebebe;
    font-size: 14px;
    overflow-y: scroll;
    outline: none;
    border-radius: 5px;
    padding: 1%;
    overflow-y: scroll;
    height: 100px;
  }

  .memory2_content_color_title {
    margin: 3% auto;
    font-size: 14px;
    font-weight: bold;
  }
  .memory2_content_color_select p {
    text-align: center;
    margin: 7% auto;
    font-weight: bold;
  }

  .memory2_btnsec {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .memory2_btnsec button {
    background-color: $SubColor;
    border: none;
    border-radius: 5px;
    padding: 2% 3%;
    margin-right: 5%;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .memory2_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .memory2_content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
    min-width: 500px;
    overflow-y: scroll;
  }

  .memory2_register_sec {
    display: flex;
    flex-direction: column;
  }
  .memory2_register_sec_detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5%;
  }
  .memory2_register_sec_detail p {
    font-size: 16px;
    font-weight: bold;
  }
  .memory2_register_sec_detail input {
    width: 350px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    font-size: 14px;
    color: gray;
    padding: 1%;
    outline: none;
  }
  .memory2_register_sec_detail_date {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 350px;
    height: 25px;
  }
  .memory2_register_sec_detail_date input {
    width: 160px;
  }
  .memory2_register_sec_date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .memory2_register_sec_date input {
    font-size: 10px;
  }

  .memory2_source_register textarea {
    width: 100%;
    resize: none;
    border: 1px solid #bebebe;
    font-size: 14px;
    overflow-y: scroll;
    outline: none;
    border-radius: 5px;
    padding: 1%;
    overflow-y: scroll;
    height: 100px;
  }

  .memory2_content_color_title {
    margin: 3% auto;
    font-size: 14px;
    font-weight: bold;
  }
  .memory2_content_color_select p {
    text-align: center;
    margin: 7% auto;
    font-weight: bold;
  }

  .memory2_btnsec {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .memory2_btnsec button {
    background-color: $SubColor;
    border: none;
    border-radius: 5px;
    padding: 2% 3%;
    margin-right: 5%;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 14px;
  }
}

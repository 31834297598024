@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 645px) {
  .noevent_bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #28cece;
    z-index: 3;
    top: 0;
  }

  .noevent_logo_sec {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .noevent_logo {
    width: 25%;
    margin: 2% auto 10% auto;
    filter: brightness(0) invert(1);
  }

  .noevent_text_sec {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: flex-start;
    margin: 30% auto 0 auto;
  }
  .noevent_text_sec p {
    margin-bottom: 3%;
    color: white;
  }
  .noevent_text_sec p:nth-child(1) {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  .noevent_text_sec p:nth-child(2),
  .noevent_text_sec p:nth-child(3) {
    font-size: 16px;
    text-align: center;
  }
  .noevent_button {
    background-color: #fff;
    color: #1b9898;
    padding: 2% 3%;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 15px;
    margin-top: 5%;
  }
  .noevent_avocado {
    position: absolute;
    width: 30%;
    height: auto;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  .noevent_button_sec {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (min-width: 646px) and (max-width: 1579px) {
  .noevent_bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #28cece;
    z-index: 1;
    top: -14px;
  }

  .noevent_logo_sec {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .noevent_logo {
    width: 25%;
    margin: 2% auto 10% auto;
    filter: brightness(0) invert(1);
  }

  .noevent_text_sec {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: flex-start;
    margin: 20% auto 0 auto;
  }
  .noevent_text_sec p {
    margin-bottom: 3%;
    color: white;
  }
  .noevent_text_sec p:nth-child(1) {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
  }
  .noevent_text_sec p:nth-child(2),
  .noevent_text_sec p:nth-child(3) {
    font-size: 20px;
    text-align: center;
  }
  .noevent_button {
    background-color: #fff;
    color: #1b9898;
    padding: 1% 3%;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 15px;
  }
  .noevent_avocado {
    position: absolute;
    width: 20%;
    height: auto;
    top: 41%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .noevent_button_sec {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 1580px) and (max-width: 30000px) {
  .noevent_bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #28cece;
    z-index: 1;
    top: -21px;
  }

  .noevent_logo_sec {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .noevent_logo {
    width: 300px;
    margin: 2% auto 10% auto;
    filter: brightness(0) invert(1);
  }

  .noevent_text_sec {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: flex-start;
    margin: 5% auto 0 auto;
    position: relative;
  }
  .noevent_text_sec p {
    margin-bottom: 3%;
    color: white;
  }
  .noevent_text_sec p:nth-child(1) {
    font-size: 36px;
    font-weight: bold;
  }
  .noevent_text_sec p:nth-child(2),
  .noevent_text_sec p:nth-child(3) {
    font-size: 20px;
  }
  .noevent_button {
    background-color: #fff;
    color: #1b9898;
    padding: 1% 3%;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 15px;
  }
  .noevent_avocado {
    position: absolute;
    width: 450px;
    height: 450px;
    right: -5%;
    top: -52%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 430px) {
  .agr_sec_all {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
  }

  .agr_sec_all .agr_title1 {
    border-bottom: 1px solid #e5e5e5;
  }
}

@media only screen and (min-width: 431px) and (max-width: 1023px) {
}

@media only screen and (min-width: 1024px) and (max-width: 30000px) {
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .adpopcorn_sec_all {
    position: relative;
  }
  .adpopcorn_signformtext {
    color: $MainColor;
    position: absolute;
    top: 64.6%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .adpopcorn_signformtext p {
    margin-bottom: 1%;
    font-size: 12px;
  }
  .adpopcorn_signformtext p:nth-child(2) {
    margin-right: 1%;
    margin-left: 1%;
    font-size: 14px;
    font-weight: bold;
  }
  .adpopcorn_signformtext p:nth-child(3) {
    display: none;
  }
  .adpopcorn_btn {
    width: 100%;
    position: absolute;
    top: 66.7%;
    background-color: $MainColor;
    color: white;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }
}

@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .adpopcorn_sec_all {
    position: relative;
  }
  .adpopcorn_signformtext {
    color: $MainColor;
    position: absolute;
    top: 64.1%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .adpopcorn_signformtext p {
    margin-bottom: 1%;
    font-size: 14px;
  }
  .adpopcorn_signformtext p:nth-child(2) {
    margin-right: 1%;
    margin-left: 1%;
    font-size: 18px;
    font-weight: bold;
  }
  .adpopcorn_btn {
    width: 100%;
    position: absolute;
    top: 66.6%;
    background-color: $MainColor;
    color: white;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: bold;
    font-size: 24px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .adpopcorn_sec_all {
    position: relative;
  }
  .adpopcorn_signformtext {
    color: $MainColor;
    position: absolute;
    top: 64.8%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .adpopcorn_signformtext p {
    margin-bottom: 1%;
    font-size: 20px;
  }
  .adpopcorn_signformtext p:nth-child(2) {
    margin-right: 1%;
    margin-left: 1%;
    font-size: 24px;
    font-weight: bold;
  }
  .adpopcorn_btn {
    width: 100%;
    position: absolute;
    top: 67%;
    background-color: $MainColor;
    color: white;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-weight: bold;
    font-size: 30px;
    cursor: pointer;
  }
}

@import "../Variables.scss";

.check__line input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.check__line input[type="checkbox"] + label {
  display: inline-block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}
.check__line input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 16px;
  height: 16px;
  text-align: center;
  background-color: $MainColor;
  box-sizing: border-box;
  border-radius: 2px;
}

/* 보여질 부분의 스타일을 추가하면 된다. */
.check__line input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  content: "✔";
  color: $checkColor;
  text-align: center;
  background-color: $MainColor;
  background-position: center center;
  border-radius: 2px;
}

.check__line input[type="checkbox"] + label span {
  font-weight: bold;
  font-size: 11px;
  line-height: 26px;
  letter-spacing: -1px;
  color: $checkSpanColor;
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .insurance_cert_modal {
    display: none;
  }

  .insurance_cert_modal.open {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  .insurance_modal_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .insurance_modal_content {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1200px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
  .insurance_modal_upright {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 40px 0;
    border-color: transparent $MainColor transparent transparent;
    position: absolute;
    right: 0;
  }

  .insurance_modal_downleft {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 0 40px;
    border-color: transparent transparent transparent $MainColor;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .modal_cert_logo {
    width: 40%;
    max-width: 225px;
  }
  .modal_cert_title p {
    font-size: 16px;
    font-weight: bold;
    margin: 5% auto 3% auto;
  }
  .modal_cert_content p {
    line-height: 170%;
    font-size: 12px;
  }
  .modal_cert_name p {
    font-size: 14px;
    margin-top: 5%;
  }
  .modal_cert_boundary {
    height: 30px;
    background-color: #f5f5f5;
    width: 100%;
  }

  .modal_cert_up {
    padding: 5%;
  }
  .modal_cert_down {
    padding: 5%;
  }
  .modal_cert_down_table {
    width: 100%;
    height: 200px;
  }
  .modal_cert_down_tr1,
  .modal_cert_down_tr2 {
    border-bottom: 1px solid gray;
  }
  .modal_cert_down_tr1 td:nth-child(1),
  .modal_cert_down_tr2 td:nth-child(1),
  .modal_cert_down_tr3 td:nth-child(1) {
    border-right: 1px solid gray;
    text-align: center;
    width: 20%;
    font-weight: bold;
    font-size: 13px;
  }
  .modal_cert_down_tr1 td:nth-child(2),
  .modal_cert_down_tr2 td:nth-child(2),
  .modal_cert_down_tr3 td:nth-child(2) {
    padding-left: 5%;
    width: 80%;
    height: 33%;
    font-weight: 500;
    font-size: 13px;
  }

  .mypage_warranty {
    width: 100%;
  }
  .mypage_warranty_sec {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .mypage_terms_btn {
    background-color: #e5e5e5;
    border: none;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    border-radius: 5px;
    height: 25px;
  }
  .mypage_terms_btn a {
    text-decoration: none;
  }
  .modal_cert_createat_small {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    font-weight: bold;
  }

  .modal_cert_createat {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    margin: 5% auto 5% auto;
    font-weight: bold;
    font-size: 12px;
  }
  .modal_cert_btn button {
    position: absolute;
    top: 3%;
    right: 5%;
    background-color: transparent;
    border: none;
  }
  .modal_cert_btn button img {
    width: 15px;
    height: auto;
  }
  .ReactModal__Overlay--after-open {
    z-index: 3;
  }
}

@media only screen and (min-width: 431px) and (max-width: 700px) {
  .insurance_cert_modal {
    display: none;
  }

  .insurance_cert_modal.open {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .insurance_modal_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .insurance_modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 700px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
  .insurance_modal_upright {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 40px 0;
    border-color: transparent $MainColor transparent transparent;
    position: absolute;
    right: 0;
  }

  .insurance_modal_downleft {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 0 40px;
    border-color: transparent transparent transparent $MainColor;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .modal_cert_logo {
    width: 40%;
    max-width: 225px;
  }
  .modal_cert_title p {
    font-size: 20px;
    font-weight: bold;
    margin: 5% auto 3% auto;
  }
  .modal_cert_content p {
    line-height: 170%;
    font-size: 14px;
  }
  .modal_cert_name p {
    font-size: 14px;
    margin-top: 2%;
  }
  .modal_cert_boundary {
    height: 30px;
    background-color: #f5f5f5;
    width: 100%;
  }

  .modal_cert_up {
    padding: 5%;
  }
  .modal_cert_down {
    padding: 5%;
  }
  .modal_cert_down_table {
    width: 100%;
    height: 200px;
  }
  .modal_cert_down_tr1,
  .modal_cert_down_tr2 {
    border-bottom: 1px solid gray;
  }
  .modal_cert_down_tr1 td:nth-child(1),
  .modal_cert_down_tr2 td:nth-child(1),
  .modal_cert_down_tr3 td:nth-child(1) {
    border-right: 1px solid gray;
    text-align: center;
    width: 20%;
    font-weight: bold;
  }
  .modal_cert_down_tr1 td:nth-child(2),
  .modal_cert_down_tr2 td:nth-child(2),
  .modal_cert_down_tr3 td:nth-child(2) {
    padding-left: 5%;
    width: 80%;
    height: 33%;
    font-weight: 500;
    font-size: 14px;
  }

  .mypage_warranty {
    width: 100%;
  }
  .mypage_warranty_sec {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .mypage_terms_btn {
    background-color: #e5e5e5;
    border: none;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    border-radius: 5px;
    height: 25px;
  }
  .mypage_terms_btn a {
    text-decoration: none;
  }
  .modal_cert_createat_small {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    font-weight: bold;
  }

  .modal_cert_createat {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    margin: 5% auto 5% auto;
    font-weight: bold;
  }
  .modal_cert_btn button {
    position: absolute;
    top: 3%;
    right: 3%;
    background-color: transparent;
    border: none;
  }
  .modal_cert_btn button img {
    width: 20px;
    height: auto;
  }
  .ReactModal__Overlay--after-open {
    z-index: 2;
  }
}

@media only screen and (min-width: 701px) and (max-width: 30000px) {
  .insurance_cert_modal {
    display: none;
  }

  .insurance_cert_modal.open {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .insurance_modal_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .insurance_modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 700px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
  .insurance_modal_upright {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 40px 0;
    border-color: transparent $MainColor transparent transparent;
    position: absolute;
    right: 0;
  }

  .insurance_modal_downleft {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 0 40px;
    border-color: transparent transparent transparent $MainColor;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .modal_cert_logo {
    width: 40%;
    max-width: 225px;
  }
  .modal_cert_title p {
    font-size: 20px;
    font-weight: bold;
    margin: 5% auto 3% auto;
  }
  .modal_cert_content p {
    line-height: 170%;
    font-size: 14px;
  }
  .modal_cert_name p {
    font-size: 14px;
    margin-top: 2%;
  }
  .modal_cert_boundary {
    height: 30px;
    background-color: #f5f5f5;
    width: 100%;
  }

  .modal_cert_up {
    padding: 5%;
  }
  .modal_cert_down {
    padding: 5%;
  }
  .modal_cert_down_table {
    width: 100%;
    height: 200px;
  }
  .modal_cert_down_tr1,
  .modal_cert_down_tr2 {
    border-bottom: 1px solid gray;
  }
  .modal_cert_down_tr1 td:nth-child(1),
  .modal_cert_down_tr2 td:nth-child(1),
  .modal_cert_down_tr3 td:nth-child(1) {
    border-right: 1px solid gray;
    text-align: center;
    width: 20%;
    font-weight: bold;
  }
  .modal_cert_down_tr1 td:nth-child(2),
  .modal_cert_down_tr2 td:nth-child(2),
  .modal_cert_down_tr3 td:nth-child(2) {
    padding-left: 5%;
    width: 80%;
    height: 33%;
    font-weight: 500;
  }

  .modal_cert_createat {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    margin: 10% auto 5% auto;
    font-weight: bold;
  }
  .modal_cert_btn button {
    position: absolute;
    top: 3%;
    right: 3%;
    background-color: transparent;
    border: none;
  }
  .modal_cert_btn button img {
    width: 20px;
    height: auto;
  }
  .mypage_over_warranty {
    background-color: transparent;
    z-index: 5;
  }
  .mypage_warranty {
    width: 100%;
  }
  .mypage_warranty_sec {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .mypage_terms_btn {
    background-color: #e5e5e5;
    border: none;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    border-radius: 5px;
    height: 25px;
  }
  .mypage_terms_btn a {
    text-decoration: none;
  }
  .ReactModal__Overlay--after-open {
    z-index: 2;
  }
}

@import "./Variables.scss";

@media only screen and (min-width: 0px) and (max-width: 430px) {
  .mypage_all {
    width: 90%;
    max-width: 430px;
    margin: 0 auto;
  }

  .mypage_content1 {
    margin-top: 10%;
  }

  .mypage_left div {
    display: flex;
    flex-direction: row;
    margin-bottom: 10%;
  }

  .mypage_left div h2 {
    font-weight: bold;
    margin-right: 2%;
  }
  .mypage_top_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 250px;
  }
  .mypage_top_content div {
    height: 25px;
  }
  .mypage_id {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mypage_id p {
    font-size: 14px;
    font-weight: 300;
    color: gray;
    width: 100%;
    max-width: 50px;
    margin-right: 7%;
  }

  .mypage_id input {
    border: none;
    resize: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
  }

  .mypage_pw {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .mypage_pw p {
    font-size: 14px;
    font-weight: 300;
    color: gray;
    width: 100%;
    max-width: 50px;
    margin-right: 7%;
  }

  .mypage_pw input {
    border: 1px solid #e5e5e5;
    background-color: transparent;
    font-weight: 500;
    font-size: 16px;
    width: 60%;
    margin-right: 5%;
    border-radius: 5px;
    outline: none;
  }

  .mypage_pw_change_btn {
    border: none;
    background-color: $SubColor;
    color: white;
    padding: 2% 3%;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px;
    float: right;
  }

  .mypage_name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mypage_name p {
    font-size: 14px;
    font-weight: 300;
    color: gray;
    width: 100%;
    max-width: 50px;
    margin-right: 7%;
  }

  .mypage_name input {
    border: none;
    resize: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
  }

  .mypage_phone {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mypage_phone p {
    font-size: 14px;
    font-weight: 300;
    color: gray;
    width: 100%;
    max-width: 50px;
    margin-right: 7%;
  }

  .mypage_phone input {
    border: none;
    resize: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
  }

  .mypage_birth {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mypage_birth p {
    font-size: 14px;
    font-weight: 300;
    color: gray;
    width: 100%;
    max-width: 50px;
    margin-right: 7%;
  }

  .mypage_birth input {
    border: none;
    resize: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
  }

  .mypage_email {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .mypage_email p {
    font-size: 14px;
    font-weight: 300;
    color: gray;
    width: 100%;
    max-width: 50px;
    margin-right: 7%;
  }

  .mypage_email input {
    border: 1px solid #e5e5e5;
    background-color: transparent;
    font-weight: 500;
    font-size: 16px;
    width: 60%;
    margin-right: 5%;
    border-radius: 5px;
    outline: none;
  }

  .mypage_email_change_btn {
    border: none;
    background-color: $MainColor;
    color: white;
    padding: 2% 3%;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px;
    float: right;
  }

  .mypage_boundary {
    display: none;
  }

  .mypage_checkbox {
    margin-top: 15%;
  }

  .mypage_marketing {
    font-size: 14px;
    font-weight: bold;
  }

  .mypage_event_history {
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mypage_event_sec {
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    padding: 1%;
  }
  .mypage_event_sec table {
    margin: 5% auto 0 auto;
    width: 90%;
  }
  .mypage_event_content {
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    border-bottom: 1px solid #e5e5e5;
  }
  .mypage_event_content:last-child {
    border-bottom: 1px solid white;
  }

  .mypage_event_content_left_td {
    width: 25%;
    text-align: center;
  }
  .mypage_event_content_right_td {
    width: 75%;
    padding-left: 3%;
  }

  .mypage_checkbox input[type="checkbox"] {
    width: 15px;
    height: 20px;
    margin: 10px 5px 10px -5px;
  }

  .mypage_marketing_checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1%;
  }

  .mypage_marketing_checkbox label {
    font-size: 12px;
    font-weight: 500;
  }

  .mypage_save button {
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 5px;
    background-color: $SubColor;
    color: white;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3%;
    margin-top: 10%;
  }

  .mypage_btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .mypage_btn div {
    width: 32%;
  }

  .mypage_btn button {
    width: 100%;
    font-size: 13px;
    padding: 10% 0;
    background-color: #f3f3f3;
    border: none;
    border-radius: 5%;
    color: black;
  }

  .mypage_exit {
    display: flex;
    justify-content: right;
    font-size: 12px;
    font-weight: 500;
    color: gray;
    margin-top: 5%;
    margin-bottom: 15%;
  }
}

@media only screen and (min-width: 431px) and (max-width: 30000px) {
  .mypage_text {
    position: relative;
    margin: 5%;
    cursor: pointer;
  }

  .mypage_all {
    width: 90%;
    max-width: 430px;
    margin: 0 auto;
  }

  .mypage_content1 {
    margin-top: 22%;
  }

  .mypage_left div {
    display: flex;
    flex-direction: row;
    margin-bottom: 10%;
  }

  .mypage_left div h2 {
    font-weight: bold;
    margin-right: 2%;
  }

  .mypage_id {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2%;
  }

  .mypage_id p {
    font-size: 14px;
    font-weight: 300;
    color: gray;
    width: 100%;
    max-width: 50px;
    margin-right: 7%;
  }

  .mypage_id input {
    border: none;
    resize: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
  }

  .mypage_pw {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2%;
  }

  .mypage_pw_small {
    display: flex;
    flex-direction: column;
  }

  .mypage_pw p {
    font-size: 14px;
    font-weight: 300;
    color: gray;
    width: 100%;
    max-width: 50px;
    margin-right: 7%;
  }

  .mypage_pw input {
    border: 1px solid #e5e5e5;
    background-color: transparent;
    font-weight: 500;
    font-size: 10px;
    width: 60%;
    margin-right: 5%;
  }

  .mypage_pw_change_btn {
    border: none;
    background-color: $SubColor;
    color: white;
    padding: 2% 3%;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px;
    float: right;
  }

  .mypage_name {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2%;
  }

  .mypage_name p {
    font-size: 14px;
    font-weight: 300;
    color: gray;
    width: 100%;
    max-width: 50px;
    margin-right: 7%;
  }

  .mypage_name input {
    border: none;
    resize: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
  }

  .mypage_phone {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2%;
  }

  .mypage_phone p {
    font-size: 14px;
    font-weight: 300;
    color: gray;
    width: 100%;
    max-width: 50px;
    margin-right: 7%;
  }

  .mypage_phone input {
    border: none;
    resize: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
  }

  .mypage_birth {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3%;
  }

  .mypage_birth p {
    font-size: 14px;
    font-weight: 300;
    color: gray;
    width: 100%;
    max-width: 50px;
    margin-right: 7%;
  }

  .mypage_birth input {
    border: none;
    resize: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
  }

  .mypage_email {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 3%;
  }

  .mypage_email p {
    font-size: 14px;
    font-weight: 300;
    color: gray;
    width: 100%;
    max-width: 50px;
    margin-right: 7%;
  }

  .mypage_email input {
    border: 1px solid #e5e5e5;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    width: 60%;
    margin-right: 5%;
    outline: none;
  }

  .mypage_email_change_btn {
    border: none;
    background-color: $MainColor;
    color: white;
    padding: 2% 3%;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px;
    float: right;
  }

  .mypage_boundary {
    display: none;
  }

  .mypage_checkbox {
    margin-top: 15%;
  }

  .mypage_marketing {
    font-size: 14px;
    font-weight: bold;
  }

  .mypage_event_history {
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mypage_event_sec {
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    padding: 1%;
  }
  .mypage_event_sec table {
    margin: 5% auto 0 auto;
    width: 90%;
  }
  .mypage_event_content {
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    border-bottom: 1px solid #e5e5e5;
  }
  .mypage_event_content:last-child {
    border-bottom: 1px solid white;
  }

  .mypage_event_content_left_td {
    width: 25%;
    text-align: center;
  }
  .mypage_event_content_right_td {
    width: 75%;
    padding-left: 3%;
  }

  .mypage_checkbox input[type="checkbox"] {
    width: 15px;
    height: 20px;
    margin: 10px 5px 10px -5px;
  }

  .mypage_marketing_checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1%;
  }

  .mypage_marketing_checkbox label {
    font-size: 12px;
    font-weight: 500;
  }

  .mypage_save button {
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 5px;
    background-color: $SubColor;
    color: white;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3%;
    margin-top: 10%;
  }

  .mypage_btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .mypage_btn button {
    width: 110px;
    font-size: 13px;
    padding: 10% 0;
    background-color: #f3f3f3;
    border: none;
    border-radius: 5%;
    color: black;
  }

  .mypage_exit {
    display: flex;
    justify-content: right;
    font-size: 12px;
    font-weight: 500;
    color: gray;
    margin-top: 5%;
    margin-bottom: 15%;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .toplogo {
    width: 30%;
    cursor: pointer;
  }
  .mainlogo {
    width: 100%;
  }

  /* 여기서부터 햄버거 */

  .nav_page_title {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 10%;
  }
  .nav_page_title p {
    font-weight: bold;
  }
  .Sec_header {
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 40px;
  }
  .more_button {
    border: none;
    background-color: transparent;
    position: relative;
    width: 100%;
  }
  .more_button_icon,
  .more_button_icon.active {
    width: 25px;
    height: 17px;
  }
  .main_navigation {
    opacity: 0;
    z-index: 2;
    width: 0%;
    height: auto;
    right: 0%;
    background-color: rgba(239, 239, 239, 0.97);
    visibility: hidden;
    transform: translateX(100%);
    transition: 0.2s;
    position: absolute;
    top: 40px;
    overflow-x: hidden;
  }

  .main_navigation.active {
    width: 85%;
    opacity: 1;
    margin-left: 40%;
    visibility: visible;
    transform: translateX(0);
    transition: 0.2s;
    position: absolute;
    top: 40px;
    height: auto;
    overflow-y: scroll;
    border-left: 1px solid #e5e5e5;
    overflow-x: hidden;
  }
  .pc_toparea,
  .pc_toparea_sec,
  .nav_pc_menu,
  .nav_pc_ver {
    display: none;
  }
  .nav_top_sec {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 90%;
    margin: 5% auto 0 auto;
    justify-content: space-between;
  }
  .nav_top_sec_left {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nav_user_profilesec {
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5%;
  }

  .nav_user_profile {
    border-radius: 50%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100%;
  }
  .nav_user_profile_audit {
    position: absolute;
    bottom: -38px;
    left: 0;
    z-index: 1;
    background-color: black;
    opacity: 0.3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50px;
    height: 50px;
    text-align: center;
  }
  .nav_user_profile_audit p {
    background-color: black;
    font-size: 10px;
    color: white;
  }

  .nav_username {
    width: 50%;
    height: auto;
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    white-space: normal;
    word-wrap: break-word;
  }

  .nav_username_guest_sec,
  .nav_username_guest {
    width: 90%;
    margin: 5% auto;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
  }

  .nav_username_guest span:nth-child(1) {
    color: $TextColor;
    margin-right: 5%;
  }

  .nav_setting {
    width: 30px;
    height: 30px;
  }

  .nav_logout {
    font-size: 12px;
  }

  .nav_top_menu_sec {
    display: flex;
    flex-direction: row;
    margin: 10% auto;
    width: 60%;
    justify-content: space-between;
  }
  .nav_top_menu_sec div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .nav_top_menu_sec div img {
    margin-bottom: 15%;
    width: 60px;
    border-radius: 50%;
  }
  .nav_top_menu_sec div span {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }

  .nav_menu {
    display: flex;
    flex-direction: column;
  }
  .nav_menu div {
    border-top: 1px solid gray;
    width: 95%;
    margin: 0 auto;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .nav_menu img {
    width: 5%;
    margin-right: 5%;
    transform: rotate(270deg);
  }
  .nav_menu div span {
    margin-left: 5%;
    font-size: 14px;
    font-weight: 300;
  }
  .nav_menu_last {
    margin-bottom: 20% !important;
  }

  .nav_eventbanner {
    width: 100%;
  }

  .nav_event_img {
    width: 100%;
  }

  .nav_terms_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin: 3% auto;
  }
  .nav_terms_sec span {
    font-size: 12px;
    color: gray;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .mainlogo {
    width: 100%;
    max-width: 200px;
    min-width: 100px;
    cursor: pointer;
  }

  /* 여기서부터 햄버거 */

  .nav_page_title {
    display: flex;
    justify-content: left;
    width: 100%;
    margin-left: 5%;
  }
  .nav_page_title p {
    font-weight: bold;
  }
  .Sec_header {
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    margin: 3% auto;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .more_button {
    display: none;
    border: none;
    background-color: transparent;
    position: relative;
    width: 100%;
  }
  .more_button_icon,
  .more_button_icon.active {
    width: 25px;
    height: 17px;
  }

  .main_navigation {
    opacity: 0;
    z-index: 2;
    width: 0%;
    height: auto;
    right: 0%;
    background-color: rgba(239, 239, 239, 0.97);
    visibility: hidden;
    transform: translateX(100%);
    transition: 0.2s;
    position: absolute;
    top: 43px;
    overflow-x: hidden;
  }

  .main_navigation.active {
    width: 85%;
    opacity: 1;
    margin-left: 40%;
    visibility: visible;
    transform: translateX(0);
    transition: 0.2s;
    position: absolute;
    top: 43px;
    height: auto;
    overflow-y: scroll;
    border-left: 1px solid #e5e5e5;
    overflow-x: hidden;
  }
  .pc_toparea_sec {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
  }
  .pc_toparea_sec div {
    width: 100px;
  }
  .pc_toparea_sec div span {
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .nav_top_sec {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 90%;
    margin: 5% auto 0 auto;
    justify-content: space-between;
  }
  .nav_top_sec_left {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nav_user_profilesec {
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5%;
  }

  .nav_user_profile {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .nav_user_profile_audit {
    position: absolute;
    bottom: -38px;
    left: 0;
    z-index: 1;
    background-color: black;
    opacity: 0.3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50px;
    height: 50px;
    text-align: center;
  }
  .nav_user_profile_audit p {
    background-color: black;
    font-size: 10px;
    color: white;
  }

  .nav_username {
    width: 50%;
    height: auto;
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    white-space: normal;
    word-wrap: break-word;
  }

  .nav_setting {
    width: 20%;
    height: 20%;
  }

  .nav_logout {
    font-size: 12px;
  }

  .nav_top_menu_sec {
    display: flex;
    flex-direction: row;
    margin: 10% auto;
    width: 60%;
    justify-content: space-between;
  }
  .nav_top_menu_sec div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .nav_top_menu_sec div img {
    margin-bottom: 15%;
    width: 60px;
    border-radius: 50%;
  }
  .nav_top_menu_sec div span {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }

  .nav_menu {
    display: flex;
    flex-direction: column;
  }
  .nav_menu div {
    border-top: 1px solid gray;
    width: 95%;
    margin: 0 auto;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .nav_menu img {
    width: 5%;
    margin-right: 5%;
    transform: rotate(270deg);
  }
  .nav_menu div span {
    margin-left: 5%;
    font-size: 14px;
    font-weight: 300;
  }

  .nav_menu_last {
    margin-bottom: 20% !important;
  }
  .InfoOne.clicked,
  .SecureTravel.clicked,
  .Coupon.clicked,
  .Event.clicked {
    color: $SubColor;
    font-weight: bold;
  }

  .nav_eventbanner {
    width: 100%;
  }

  .nav_event_img {
    width: 100%;
  }

  .nav_terms_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin: 3% auto;
  }
  .nav_terms_sec span {
    font-size: 12px;
    color: gray;
  }
  .nav_pc_ver {
    width: 90%;
    height: 30px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    margin: 0 auto 1% auto;
  }
  .nav_pc_menu {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .mainlogo {
    width: 200px;
    cursor: pointer;
  }

  /* 여기서부터 햄버거 */

  .nav_page_title {
    display: flex;
    justify-content: left;
    width: 100%;
    margin-left: 5%;
  }
  .nav_page_title p {
    font-weight: bold;
    font-size: 20px;
  }
  .Sec_header {
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    margin: 1% auto;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .more_button {
    border: none;
    background-color: transparent;
    position: relative;
    width: 100%;
    display: none;
  }
  .more_button_icon,
  .more_button_icon.active {
    width: 25px;
    height: 17px;
  }

  .main_navigation {
    opacity: 0;
    z-index: 2;
    width: 0%;
    height: auto;
    right: 0%;
    background-color: rgba(239, 239, 239, 0.97);
    visibility: hidden;
    transform: translateX(100%);
    transition: 0.2s;
    position: absolute;
    top: 43px;
    overflow-x: hidden;
  }

  .main_navigation.active {
    width: 85%;
    opacity: 1;
    margin-left: 40%;
    visibility: visible;
    transform: translateX(0);
    transition: 0.2s;
    position: absolute;
    top: 43px;
    height: auto;
    overflow-y: scroll;
    border-left: 1px solid #e5e5e5;
    overflow-x: hidden;
  }

  .pc_toparea_sec {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
  }
  .pc_toparea_sec div {
    width: 100px;
  }
  .pc_toparea_sec div span {
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .nav_top_sec {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 90%;
    margin: 5% auto 0 auto;
    justify-content: space-between;
  }
  .nav_top_sec_left {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nav_user_profilesec {
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
  }

  .nav_user_profile {
    border-radius: 50%;
    width: 100%;
  }
  .nav_user_profile_audit {
    position: absolute;
    bottom: -38px;
    left: 0;
    z-index: 1;
    background-color: black;
    opacity: 0.3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50px;
    height: 50px;
    text-align: center;
  }
  .nav_user_profile_audit p {
    background-color: black;
    font-size: 10px;
    color: white;
  }

  .nav_username {
    width: 50%;
    height: auto;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }

  .nav_setting {
    width: 20%;
    height: 20%;
  }

  .nav_logout {
    font-size: 12px;
  }

  .nav_top_menu_sec {
    display: flex;
    flex-direction: row;
    margin: 10% auto;
    width: 60%;
    justify-content: space-between;
  }
  .nav_top_menu_sec div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .nav_top_menu_sec div img {
    margin-bottom: 15%;
    width: 60px;
    border-radius: 50%;
  }
  .nav_top_menu_sec div span {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }

  .nav_menu {
    display: flex;
    flex-direction: column;
  }
  .nav_menu div {
    border-top: 1px solid gray;
    width: 95%;
    margin: 0 auto;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .nav_menu img {
    width: 5%;
    margin-right: 5%;
    transform: rotate(270deg);
  }
  .nav_menu div span {
    margin-left: 5%;
    font-size: 14px;
    font-weight: 300;
  }

  .nav_menu_last {
    margin-bottom: 20% !important;
  }

  .InfoOne.clicked,
  .SecureTravel.clicked,
  .Coupon.clicked,
  .Event.clicked {
    color: $MainColor;
    font-weight: bold;
  }

  .nav_eventbanner {
    width: 100%;
  }

  .nav_event_img {
    width: 100%;
  }

  .nav_terms_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin: 3% auto;
  }
  .nav_terms_sec span {
    font-size: 12px;
    color: gray;
  }
  .nav_pc_ver {
    width: 90%;
    height: 30px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    margin: 0 auto 1% auto;
  }
  .nav_pc_menu {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .infoone_maincategory_sec {
    width: 96%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .infoone_maincategory_sec {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2% auto 0 auto;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .infoone_maincategory_sec {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2% auto 0 auto;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .event_detail_title {
    margin: 0% auto 5% auto;
  }

  .event_detail_title h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2%;
  }

  .event_detail_title h2 {
    font-size: 14px;
    color: #9f9f9f;
    margin-bottom: 5%;
  }
  .samsung_number {
    position: absolute;
    bottom: 23.7%;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
  }
  .event_detail_dombtn {
    bottom: 24.3%;
    cursor: pointer;
    height: 2%;
    position: absolute;
    width: 45%;
    right: 4%;
  }
  .event_detail_overbtn {
    bottom: 24.3%;
    cursor: pointer;
    height: 2%;
    position: absolute;
    width: 45%;
    left: 4%;
  }
  .modal_close_btn {
    display: flex;
    justify-content: right;
    position: absolute;
    right: 30px;
  }

  .modal_close_btn button {
    background-color: transparent;
    border: none;
  }
  .modal_close_img {
    width: 15px;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .event_detail_title {
    margin: 5% auto;
  }

  .event_detail_title h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2%;
  }

  .event_detail_title h2 {
    font-size: 14px;
    color: #9f9f9f;
    margin-bottom: 5%;
  }
  .samsung_number {
    position: absolute;
    bottom: 23.7%;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
  }
  .event_detail_dombtn {
    bottom: 24.3%;
    cursor: pointer;
    height: 2%;
    position: absolute;
    width: 45%;
    right: 4%;
  }
  .event_detail_overbtn {
    bottom: 24.3%;
    cursor: pointer;
    height: 2%;
    position: absolute;
    width: 45%;
    left: 4%;
  }
  .modal_close_btn {
    display: flex;
    justify-content: right;
  }

  .modal_close_btn button {
    background-color: transparent;
    border: none;
  }
  .modal_close_img {
    width: 20px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .event_detail_title {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .event_detail_title h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1%;
  }

  .event_detail_title h2 {
    font-size: 16px;
    color: #9f9f9f;
    margin-bottom: 3%;
  }
  .samsung_number {
    position: absolute;
    bottom: 23.7%;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
  }
  .event_detail_dombtn {
    bottom: 24.3%;
    cursor: pointer;
    height: 2%;
    position: absolute;
    width: 45%;
    right: 4%;
  }
  .event_detail_overbtn {
    bottom: 24.3%;
    cursor: pointer;
    height: 2%;
    position: absolute;
    width: 45%;
    left: 4%;
  }
  .modal_close_btn {
    display: flex;
    justify-content: right;
  }

  .modal_close_btn button {
    background-color: transparent;
    border: none;
  }
  .modal_close_img {
    width: 20px;
  }
}

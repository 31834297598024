@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .login_sec_all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    width: 300px;
    height: 515px;
    margin: 5% auto 5% auto;
  }
  .loginbox {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #e7e7e7;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  //   .loginbox input {
  //     font-size: 16px;
  //     width: 90%;
  //     margin-bottom: 3%;
  //     font-weight: 300;
  //     outline: none;
  //   }
  .loginsign {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 14px;
    position: relative;
  }
  .loginsign_text {
    font-weight: bold;
    position: relative;
    z-index: 1;
    color: white;
    border-bottom: 1px solid white;
    line-height: 150%;
  }

  .loginsign_text2 {
    width: 150px;
    font-size: 12px;
    text-align: center;
    color: white;
    position: relative;
    z-index: 1;
    line-height: 150%;
    padding: 30px 2% 0 2%;
  }

  .loginsign_btn {
    color: $SubColor;
    padding: 10px;
    background-color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    width: 150px;
    margin: 20px auto 0px auto;
    position: relative;
    z-index: 1;
    border: none;
    border-radius: 20px;
  }

  .loginsign_btn:hover {
    color: white;
    padding: 10px;
    background-color: $SubColor;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    width: 150px;
    margin: 20px auto 0px auto;
    position: relative;
    z-index: 1;
    border: none;
    border-radius: 20px;
  }
  .loginbg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .login_sec_all {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    width: 400px;
    height: 350px;
    margin: 10% auto 10% auto;
    border: 1px solid #e7e7e7;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .loginbox {
    width: 200px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  //   .loginbox input {
  //     width: 90%;
  //     margin-bottom: 3%;
  //     font-weight: 300;
  //   }
  .loginsign {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 14px;
    position: relative;
  }

  .loginsign_text {
    font-weight: bold;
    position: relative;
    z-index: 1;
    color: white;
    border-bottom: 1px solid white;
    line-height: 150%;
  }

  .loginsign_text2 {
    width: 150px;
    font-size: 12px;
    text-align: center;
    color: white;
    position: relative;
    z-index: 1;
    line-height: 150%;
    padding: 30px 2% 0 2%;
  }

  .loginsign_btn {
    color: $SubColor;
    padding: 10px;
    background-color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    width: 150px;
    margin: 20px auto 0px auto;
    position: relative;
    z-index: 1;
    border: none;
    border-radius: 20px;
  }

  .loginsign_btn:hover {
    color: white;
    padding: 10px;
    background-color: $SubColor;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    width: 150px;
    margin: 20px auto 0px auto;
    position: relative;
    z-index: 1;
    border: none;
    border-radius: 20px;
  }
  .loginbg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .login_sec_all {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    width: 700px;
    height: 400px;
    margin: 5% auto;
    border: 1px solid #e7e7e7;
    border-radius: 15px;
  }

  .loginbox {
    width: 400px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  //   .loginbox input {
  //     width: 270px;
  //     margin-bottom: 3%;
  //     font-weight: 300;
  //   }

  .loginsign {
    width: 312px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 14px;
    position: relative;
  }

  .loginsign_text {
    font-weight: bold;
    position: relative;
    z-index: 1;
    color: white;
    border-bottom: 1px solid white;
    line-height: 150%;
  }

  .loginsign_text2 {
    width: 200px;
    font-size: 12px;
    text-align: center;
    color: white;
    margin-top: 30px;
    line-height: 15px;
    position: relative;
    z-index: 1;
    line-height: 150%;
  }

  .loginsign_btn {
    color: $SubColor;
    padding: 10px;
    background-color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    width: 200px;
    margin: 20px auto 0px auto;
    position: relative;
    z-index: 1;
    border: none;
    border-radius: 20px;
  }

  .loginsign_btn:hover {
    color: white;
    padding: 10px;
    background-color: $SubColor;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    width: 200px;
    margin: 20px auto 0px auto;
    position: relative;
    z-index: 1;
    border: none;
    border-radius: 20px;
  }
  .loginbg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 430px) {
  .travelshop_backimg_sec {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
    margin-top: 15%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 699px) {
}

@media only screen and (min-width: 0px) and (max-width: 699px) {
  .shop_all {
    margin: 5% auto 0 auto;
    width: 90%;
  }
  .shop_title_sec {
    display: flex;
    flex-direction: column;
    align-items: left;
    position: absolute;
    z-index: 1;
    top: 39%;
    left: 10%;
  }
  .shop_title_sec h1 {
    font-size: 30px;
    font-weight: bold;
    color: white;
  }

  .shop_title_sec h2 {
    font-size: 16px;
    color: #9f9f9f;
    margin: 3% auto;
    color: white;
  }
  .shop_item {
    position: relative;
    margin: 0 auto;
    cursor: pointer;
  }
  .shop_item_img {
    width: 170px;
    height: 100px;
    border: 1px solid #e5e5e5;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    cursor: pointer;
    overflow: hidden;
  }

  .shop_above_category {
    width: 80px;
    height: 20px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: white;
    font-weight: bold;
    display: flex;
    position: absolute;
    z-index: 1;
    background-color: #ffb134;
    border-radius: 4px;
    margin-left: 5%;
    top: 45%;
  }

  .shop_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .shop_text_sec {
    padding: 5% 5%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    line-height: 150%;
    width: 170px;
  }
  .shop_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto 10% auto;
    align-items: center;
  }
  .shop_sec div {
    margin-bottom: 5%;
  }
  .shop_title {
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }

  .shop_subtitle {
    font-size: 14px;
    cursor: pointer;
  }
  .shop_context {
    font-weight: bold;
    font-size: 16px;
    color: rgb(254, 64, 64);
  }

  .travelshop_backimg_sec {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
  }
  .travelshop_backimg_sec::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 300px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  .travelshop_backimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .shop_all {
    margin: 5% auto 0 auto;
    width: 90%;
  }
  .shop_title_sec {
    display: flex;
    flex-direction: column;
    align-items: left;
    position: absolute;
    z-index: 1;
    top: 39%;
    left: 10%;
  }
  .shop_title_sec h1 {
    font-size: 40px;
    font-weight: bold;
    color: white;
  }

  .shop_title_sec h2 {
    font-size: 20px;
    color: #9f9f9f;
    margin: 3% auto;
    color: white;
  }
  .shop_item {
    position: relative;
    margin-bottom: 15%;
    cursor: pointer;
  }
  .shop_item_img {
    width: 250px;
    height: 125px;
    border: 1px solid #e5e5e5;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    cursor: pointer;
    overflow: hidden;
  }

  .shop_above_category {
    width: 70px;
    height: 15px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: white;
    font-weight: bold;
    display: flex;
    position: absolute;
    z-index: 1;
    background-color: #ffb134;
    border-radius: 4px;
    margin-left: 5%;
    top: 53%;
  }

  .shop_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .shop_text_sec {
    padding: 5% 5%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    line-height: 150%;
  }
  .shop_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto 10% auto;
  }
  .shop_title {
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }

  .shop_subtitle {
    font-size: 12px;
    cursor: pointer;
  }
  .shop_context {
    font-weight: bold;
    font-size: 16px;
    color: rgb(254, 64, 64);
  }

  .travelshop_backimg_sec {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
  }
  .travelshop_backimg_sec::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 300px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  .travelshop_backimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .shop_all {
    margin: 3% auto 0 auto;
    width: 50%;
    min-width: 820px;
    max-width: 1200px;
  }
  .shop_title_sec {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    top: 39%;
    left: 10%;
  }
  .shop_title_sec h1 {
    font-size: 40px;
    font-weight: bold;
    color: white;
  }

  .shop_title_sec h2 {
    font-size: 20px;
    color: #9f9f9f;
    margin: 3% auto;
    color: white;
  }
  .shop_item {
    position: relative;
    margin-bottom: 15%;
    cursor: pointer;
  }
  .shop_item_img {
    width: 260px;
    height: 150px;
    border: 1px solid #e5e5e5;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    cursor: pointer;
    overflow: hidden;
  }

  .shop_above_category {
    width: 80px;
    height: 20px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: white;
    font-weight: bold;
    display: flex;
    position: absolute;
    z-index: 1;
    background-color: #ffb134;
    border-radius: 4px;
    margin-left: 5%;
    top: 55%;
  }

  .shop_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .shop_text_sec {
    padding: 5% 5%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    line-height: 150%;
  }
  .shop_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto 10% auto;
  }
  .shop_title {
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }

  .shop_subtitle {
    font-size: 14px;
    cursor: pointer;
  }
  .shop_context {
    font-weight: bold;
    font-size: 16px;
    color: rgb(254, 64, 64);
  }

  .travelshop_backimg_sec {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
  }
  .travelshop_backimg_sec::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 300px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  .travelshop_backimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .nmpregister_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto 5% auto;
  }
  .npmregister_modal_location_input {
    width: 25%;
    margin-right: 1%;
    text-align: center;
    outline: none;
    font-size: 16px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    height: 30px;
  }
  .npmregister_explain_sec {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 2% auto 2% auto;
  }
  .npmregister_explain_sec input {
    width: 100%;
    text-align: left;
    padding-left: 2%;
    overflow-y: scroll;
    padding-right: 8%;
    outline: none;
    font-size: 16px;
    border: 1px solid #c7c7c7;
    height: 30px;
    border-radius: 5px;
  }

  .npmregister_modal_explain_plus {
    width: 15px;
    position: absolute;
    top: 25%;
    right: 25px;
  }
  .npmregister_modal_explain_plus img {
    width: 100%;
    filter: brightness(-100%);
  }
  .npmregister_modal_explain_delete {
    width: 15px;
    position: absolute;
    top: 25%;
    right: 5px;
  }
  .npmregister_modal_explain_delete img {
    width: 100%;
  }

  .npmregister_modal_location_delete {
    font-size: 12px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    margin-top: 1%;
    background-color: $SubColor;
    color: white;
    font-weight: bold;
    padding: 2%;
  }

  .npmregister_modal_location_plus {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
  }
  .npmregister_modal_location_plus hr {
    margin-top: 1%;
    width: 60px;
    border: 1px solid $MainColor;
    opacity: 1;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .nmpregister_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto 5% auto;
  }
  .npmregister_modal_location_input {
    width: 25%;
    margin-right: 1%;
    text-align: center;
    outline: none;
    font-size: 14px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    height: 30px;
  }

  .npmregister_explain_sec {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 2% auto 2% auto;
  }
  .npmregister_explain_sec input {
    width: 100%;
    text-align: left;
    padding-left: 2%;
    overflow-y: scroll;
    padding-right: 8%;
    outline: none;
    font-size: 14px;
    border: 1px solid #c7c7c7;
    height: 30px;
    border-radius: 5px;
  }
  .npmregister_modal_explain_plus {
    width: 15px;
    position: absolute;
    top: 25%;
    right: 25px;
  }
  .npmregister_modal_explain_plus img {
    width: 100%;
    filter: brightness(-100%);
    cursor: pointer;
  }
  .npmregister_modal_explain_delete {
    width: 15px;
    position: absolute;
    top: 25%;
    right: 5px;
  }

  .npmregister_modal_explain_delete img {
    width: 100%;
    cursor: pointer;
  }

  .npmregister_modal_location_delete {
    font-size: 12px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    margin-top: 1%;
    background-color: $SubColor;
    color: white;
    font-weight: bold;
    padding: 2%;
  }

  .npmregister_modal_location_plus {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
  .npmregister_modal_location_plus span {
    cursor: pointer;
  }
  .npmregister_modal_location_plus hr {
    margin-top: 1%;
    width: 60px;
    border: 1px solid $MainColor;
    opacity: 1;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .nmpregister_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto 5% auto;
  }
  .npmregister_modal_location_input {
    width: 25%;
    margin-right: 1%;
    text-align: center;
    outline: none;
    font-size: 14px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    height: 30px;
  }

  .npmregister_explain_sec {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 2% auto 2% auto;
  }
  .npmregister_explain_sec input {
    width: 100%;
    text-align: left;
    padding-left: 2%;
    overflow-y: scroll;
    padding-right: 8%;
    outline: none;
    font-size: 14px;
    border: 1px solid #c7c7c7;
    height: 30px;
    border-radius: 5px;
  }

  .npmregister_modal_explain_plus {
    width: 15px;
    position: absolute;
    top: 25%;
    right: 25px;
  }
  .npmregister_modal_explain_plus img {
    width: 100%;
    filter: brightness(-100%);
    cursor: pointer;
  }
  .npmregister_modal_explain_delete {
    width: 15px;
    position: absolute;
    top: 25%;
    right: 5px;
  }

  .npmregister_modal_explain_delete img {
    width: 100%;
    cursor: pointer;
  }
  .npmregister_modal_location_delete {
    font-size: 12px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    margin-top: 1%;
    background-color: $SubColor;
    color: white;
    font-weight: bold;
    padding: 2%;
  }

  .npmregister_modal_location_plus {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
  .npmregister_modal_location_plus span {
    cursor: pointer;
  }
  .npmregister_modal_location_plus hr {
    margin-top: 1%;
    width: 60px;
    border: 1px solid $MainColor;
    opacity: 1;
  }
}

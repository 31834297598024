@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .main_title_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 5% auto auto 5%;
    justify-content: left;
    align-items: center;
  }
  .main_title_sec img {
    width: 5%;
    height: auto;
    margin-right: 3%;
    border-radius: 0% !important;
  }
  .main_title_sec p {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  .infoone_FL_title_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto 5% auto;
    justify-content: left;
    align-items: center;
  }
  .infoone_FL_title_sec img {
    width: 5%;
    height: auto;
    margin-right: 3%;
  }
  .infoone_FL_title_sec p {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
  .mypage_schedule_title {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  .mypage_schedule_title_sec {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 10% auto 5% 5%;
    justify-content: left;
    align-items: center;
  }
  .mypage_schedule_title_sec img {
    width: 5%;
    height: auto;
    margin-right: 3%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .main_title_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: left;
    align-items: center;
    margin: 5% auto auto 5%;
  }
  .main_title_sec img {
    width: 30px;
    height: auto;
    margin-right: 2%;
    border-radius: 0;
  }
  .main_title_sec p {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }

  .infoone_FL_title_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto 3% auto;
    justify-content: left;
    align-items: center;
  }
  .infoone_FL_title_sec img {
    width: 5%;
    height: auto;
    margin-right: 3%;
  }
  .infoone_FL_title_sec p {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
  .mypage_schedule_title {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  .mypage_schedule_title_sec {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 60%;
    margin: 0 auto 5% auto;
  }
  .mypage_schedule_title_sec img {
    width: 3%;
    height: auto;
    margin-right: 1%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .main_title_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: left;
    align-items: center;
    margin: 2% auto auto 5%;
  }
  .main_title_sec img {
    width: 30px;
    height: auto;
    margin-right: 1%;
    border-radius: 0;
  }
  .main_title_sec p {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }

  .infoone_FL_title_sec {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 0 auto 3% auto;
    justify-content: left;
    align-items: center;
  }
  .infoone_FL_title_sec img {
    width: 3%;
    height: auto;
    margin-right: 1%;
  }
  .infoone_FL_title_sec p {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
  .mypage_schedule_title {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  .mypage_schedule_title_sec {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 40%;
    margin: 0 auto 3% auto;
  }
  .mypage_schedule_title_sec img {
    width: 3%;
    height: auto;
    margin-right: 1%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 430px) {
  .termspri_select_sec {
    width: 70%;
    margin: 20% auto 1% auto;
  }
  .termspri_select {
    width: 100%;
    padding: 2%;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    cursor: pointer;
    outline: none;
    font-size: 12px;
  }
}
@media only screen and (min-width: 431px) and (max-width: 30000px) {
  .termspri_select_sec {
    width: 70%;
    margin: 2% auto;
  }
  .termspri_select {
    width: 100%;
    padding: 2%;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    cursor: pointer;
    outline: none;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 699px) {
  .mypage_coupon_prevarrow {
    transform: rotate(90deg);
    width: 20px;
    position: absolute;
    top: 50%;
    right: 5px;
    z-index: 5000;
  }
  .mypage_coupon_nextarrow {
    transform: rotate(270deg);
    width: 20px;
    position: absolute;
    top: 50%;
    left: 5px;
    z-index: 5000;
  }

  .mypage_user_coupon_item_all {
    cursor: pointer;
  }
  .mypage_user_coupon_item {
    width: 60%;
    max-width: 120px;
    height: 135px;
    padding: 20px;
    border-radius: 15px;
    position: relative;
    margin: 0 auto;
  }

  .mypage_user_coupon_item p:nth-child(1) {
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20%;
    text-align: left;
  }
  .mypage_user_coupon_item p:nth-child(2) {
    color: white;
    font-weight: 500;
    font-size: 12px;
    text-align: left;
  }

  .mypage_user_coupon_barcode_sec {
    position: absolute;
    z-index: 2;
    bottom: 5%;
    right: 5%;
    width: 20%;
    height: 15%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .mypage_user_coupon_barcode {
    position: absolute;
    z-index: 2;
    width: 80%;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .mypage_coupon_prevarrow {
    transform: rotate(90deg);
    width: 20px;
    position: absolute;
    top: 50%;
    right: 10px;
  }
  .mypage_coupon_nextarrow {
    transform: rotate(270deg);
    width: 20px;
    position: absolute;
    top: 50%;
    left: 5px;
  }
  .mypage_user_coupon_item_all {
    cursor: pointer;
  }

  .mypage_user_coupon_item {
    width: 120px;
    height: 150px;
    padding: 20px;
    border-radius: 15px;
    position: relative;
    margin: 0 auto;
  }

  .mypage_user_coupon_item p:nth-child(1) {
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20%;
    text-align: left;
  }
  .mypage_user_coupon_item p:nth-child(2) {
    color: white;
    font-weight: 500;
    font-size: 12px;
    text-align: left;
  }

  .mypage_user_coupon_barcode_sec {
    position: absolute;
    z-index: 2;
    bottom: 5%;
    right: 5%;
    width: 20%;
    height: 15%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .mypage_user_coupon_barcode {
    position: absolute;
    z-index: 2;
    width: 80%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .mypage_coupon_prevarrow {
    transform: rotate(90deg);
    width: 20px;
    position: absolute;
    top: 50%;
    right: 10px;
  }
  .mypage_coupon_nextarrow {
    transform: rotate(270deg);
    width: 20px;
    position: absolute;
    top: 50%;
    left: 5px;
  }
  .mypage_user_coupon_item_all {
    cursor: pointer;
  }

  .mypage_user_coupon_item {
    width: 130px;
    height: 160px;
    padding: 20px;
    border-radius: 15px;
    position: relative;
    margin-left: 12%;
    margin: 0 auto;
  }

  .mypage_user_coupon_item p:nth-child(1) {
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20%;
    text-align: left;
  }
  .mypage_user_coupon_item p:nth-child(2) {
    color: white;
    font-weight: 500;
    font-size: 12px;
    text-align: left;
  }

  .mypage_user_coupon_barcode_sec {
    position: absolute;
    z-index: 2;
    bottom: 5%;
    right: 5%;
    width: 20%;
    height: 15%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .mypage_user_coupon_barcode {
    position: absolute;
    z-index: 2;
    width: 80%;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .memory_dom_terms {
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: gray;
    font-weight: bold;
    margin: 10% auto;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .memory_dom_terms {
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: gray;
    font-weight: bold;
    margin: 10% auto;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .memory_dom_terms {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: gray;
    font-weight: bold;
    margin: 10% auto;
  }
}

@import "../Variables.scss";

.addrpopup_header {
  height: 40px;
  display: flex;
  text-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(159, 159, 159, 0.5098039216);
  margin-bottom: 10px;
}

.addrpopup_search {
  display: flex;
}

.addrpopup_content {
  padding: 12px;
}

.addpopup_content_detail {
  display: flex;
  cursor: pointer;
  border: 1px solid black;
  padding: 4px;
}

.addpopup_content_detail_left {
  width: 80%;
}

.addpopup_content_detail_left1 {
  display: flex;
  margin-bottom: 11px;
}

.addpopup_content_detail_right {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid black;
}

.addrpopup_paging {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 0px) and (max-width: 430px) {
  .addrpopup_overlay {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #9f9f9f82;
  }

  .addrpopup_section {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    width: 80%;
    max-width: 600px;
    // height: 600px; /* 내부 content의 높이를 조절할 수 있습니다. */
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
  }
}

@media only screen and (min-width: 431px) and (max-width: 30000px) {
  .addrpopup_overlay {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #9f9f9f82;
  }
  .addrpopup_section {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    width: 80%;
    max-width: 600px;
    height: 600px; /* 내부 content의 높이를 조절할 수 있습니다. */
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
  }
}

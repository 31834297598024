@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 699px) {
  .coupon_all {
    margin: 5% auto 0 auto;
    width: 90%;
  }
  .coupon_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto 10% auto;
    align-items: center;
  }
  .coupon_item {
    position: relative;
    margin: 0 auto 10% auto;
  }
  .coupon_item_img {
    width: 160px;
    height: 100px;
    border: 1px solid #e5e5e5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    overflow: hidden;
  }
  .coupon_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .coupon_above_category {
    width: 55px;
    height: 20px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: white;
    font-weight: bold;
    display: flex;
    position: absolute;
    z-index: 1;
    background-color: $SubColor;
    border-radius: 4px;
    margin-left: 59%;
    top: 55%;
  }
  .coupon_text_sec {
    padding: 5% 5%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 160px;
    background: white;
    height: 65px;
  }
  .coupon_title {
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    width: 55%;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .coupon_all {
    margin: 5% auto 0 auto;
    width: 90%;
  }
  .coupon_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto 10% auto;
  }
  .coupon_item {
    position: relative;
    margin-bottom: 15%;
  }
  .coupon_item_img {
    width: 250px;
    height: 125px;
    border: 1px solid #e5e5e5;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    cursor: pointer;
    overflow: hidden;
  }
  .coupon_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .coupon_above_category {
    width: 70px;
    height: 15px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: white;
    font-weight: bold;
    display: flex;
    position: absolute;
    z-index: 1;
    background-color: $SubColor;
    border-radius: 4px;
    margin-left: 5%;
    top: 67%;
  }
  .coupon_text_sec {
    padding: 5% 5%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    line-height: 150%;
  }
  .coupon_title {
    font-size: 14px;
    font-weight: bold;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .coupon_all {
    margin: 3% auto 0 auto;
    width: 50%;
    min-width: 820px;
    max-width: 1200px;
  }
  .coupon_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto 10% auto;
  }
  .coupon_item {
    position: relative;
    margin-bottom: 15%;
  }
  .coupon_item_img {
    width: 260px;
    height: 150px;
    border: 1px solid #e5e5e5;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    cursor: pointer;
    overflow: hidden;
  }
  .coupon_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .coupon_above_category {
    width: 80px;
    height: 20px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: white;
    font-weight: bold;
    display: flex;
    position: absolute;
    z-index: 1;
    background-color: $SubColor;
    border-radius: 4px;
    margin-left: 5%;
    top: 70%;
  }
  .coupon_text_sec {
    padding: 5% 5%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    line-height: 150%;
  }
  .coupon_title {
    font-weight: bold;
  }
}

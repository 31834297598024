@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .modal_samlogo {
    width: 70px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .modal_title {
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    text-align: center;
  }
  .modal_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2%;
  }

  .modal_div div {
    margin-right: 5%;
  }

  .modal_div div:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .modal_div div:nth-child(2) label {
    font-size: 12px;
  }

  .modal_div div p {
    width: 80px;
    font-weight: 500;
    font-size: 12px;
  }

  .modal_div input {
    font-weight: 300;
    font-size: 10px;
    border-radius: 5px;
    border: 1px solid gray;
  }
  .modal_div label {
    font-weight: 300;
    font-size: 12px;
  }
  .modal_date {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    color: black;
  }
  .modal_check1_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_check1_box {
    margin-left: 5% !important;
  }

  .modal_check1_text {
    font-size: 12px;
    font-weight: bold;
  }
  .modal_textarea {
    padding: 1%;
    border: 1px solid #e5e5e5;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    height: auto;
    border-radius: 5px;
  }

  .modal_textarea::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  .modal_textarea p {
    margin-bottom: 2%;
    line-height: 130%;
    font-size: 12px;
  }
  .modal_btn {
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
  }
  .modal_btn label button {
    width: 120px;
    height: 30px;
    padding: 2%;
    border-radius: 15px;
    background-color: $SubColor;
    color: white;
    font-weight: 500;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  .modal_term_btn_dom {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .modal_term_btn_dom button {
    border: none;
    border-radius: 10px;
    background: #e5e5e5;
    font-size: 12px;
    width: 130px;
    height: 30px;
    cursor: pointer;
    outline: none;
    color: black;
  }
  .modal_term_btn_dom a {
    margin-right: 3%;
  }

  .modal_thead {
    border: 1px solid gray;
    border-bottom: 1px solid gray !important;
    padding: 1%;
    text-align: center;
    vertical-align: middle;
  }

  .modal_term_btn_over {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .modal_term_btn_over button {
    border: none;
    border-radius: 10px;
    background: #e5e5e5;
    font-size: 12px;
    width: 130px;
    height: 30px;
    cursor: pointer;
    outline: none;
    color: black;
  }
  .modal_term_btn_over a {
    margin-right: 3%;
  }

  .modal_nation {
    width: 100%;
    outline: none;
    font-size: 16px;
  }

  .modal_purpose {
    width: 100%;
    font-size: 10px;
    font-weight: 300;
    outline: none;
    border-radius: 5px;
    color: black;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .modal_all {
    max-height: 750px;
    overflow-y: scroll;
  }

  .modal_all::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }
  .modal_samlogo {
    width: 130px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .modal_title {
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    text-align: center;
  }
  .modal_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2%;
  }

  .modal_div div {
    margin-right: 5%;
  }

  .modal_div div:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .modal_div div:nth-child(2) label {
    font-size: 12px;
  }

  .modal_div div p {
    width: 100px;
    font-weight: 500;
  }

  .modal_div input {
    font-weight: 300;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid gray;
  }
  .modal_div_input_readonly {
    border: 1px solid gray;
    width: 100%;
    border-radius: 5px;
    outline: none;
  }
  .modal_div_input_readonly {
    border: 1px solid gray;
    width: 100%;
    border-radius: 5px;
    outline: none;
  }
  .modal_date {
    width: 100%;
    outline: none;
  }
  .modal_check1_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_check1_box {
    margin-right: 5%;
  }

  .modal_check1_text {
    font-size: 14px;
    font-weight: bold;
  }
  .modal_textarea {
    padding: 1%;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    /* overflow-y: scroll; */
    height: auto;
  }

  .modal_textarea p {
    margin-bottom: 2%;
  }
  .modal_btn {
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }
  .modal_btn label button {
    width: 200px;
    height: 30px;
    padding: 2%;
    border-radius: 15px;
    background-color: $SubColor;
    color: white;
    font-weight: 500;
    border: none;
    cursor: pointer;
  }
  .modal_term_btn_dom {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .modal_term_btn_dom button {
    border: none;
    border-radius: 10px;
    background: #e5e5e5;
    font-size: 14px;
    width: 150px;
    height: 30px;
    cursor: pointer;
    outline: none;
  }
  .modal_term_btn_dom a {
    margin-right: 3%;
  }
  .modal_thead {
    border: 1px solid gray;
    border-bottom: 1px solid gray !important;
    padding: 1%;
    text-align: center;
    vertical-align: middle;
  }

  .modal_term_btn_over {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .modal_term_btn_over button {
    border: none;
    border-radius: 10px;
    background: #e5e5e5;
    font-size: 14px;
    width: 150px;
    height: 30px;
    cursor: pointer;
    outline: none;
  }
  .modal_term_btn_over a {
    margin-right: 3%;
  }
  .modal_nation {
    width: 100%;
    outline: none;
  }

  .modal_purpose {
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    outline: none;
    border-radius: 5px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .modal_all {
    max-height: 750px;
    overflow-y: scroll;
  }

  .modal_all::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }
  .modal_samlogo {
    width: 130px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .modal_title {
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    text-align: center;
  }
  .modal_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2%;
  }

  .modal_div div {
    margin-right: 5%;
  }

  .modal_div div:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .modal_div div:nth-child(2) label {
    font-size: 14px;
  }

  .modal_div div p {
    width: 100px;
    font-weight: 500;
  }

  .modal_div input {
    font-weight: 300;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid gray;
  }
  .modal_div_input_readonly {
    border: 1px solid gray;
    width: 100%;
    border-radius: 5px;
    outline: none;
  }
  .modal_date {
    width: 100%;
    outline: none;
  }
  .modal_check1_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_check1_box {
    margin-right: 5%;
  }

  .modal_check1_text {
    font-size: 14px;
    font-weight: bold;
  }
  .modal_textarea {
    padding: 1%;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 100%;
    height: auto;
    /* overflow-y: scroll; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
  }

  .modal_textarea p {
    margin-bottom: 2%;
    line-height: 130%;
  }
  .modal_btn {
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }

  .modal_btn label button {
    width: 200px;
    height: 30px;
    padding: 2%;
    border-radius: 15px;
    background-color: $SubColor;
    color: white;
    font-weight: 500;
    border: none;
    cursor: pointer;
  }
  .modal_term_btn_dom {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .modal_term_btn_dom button {
    border: none;
    border-radius: 10px;
    background: #e5e5e5;
    font-size: 14px;
    width: 150px;
    height: 30px;
    cursor: pointer;
    outline: none;
  }
  .modal_term_btn_dom a {
    margin-right: 3%;
  }
  .modal_thead {
    border: 1px solid gray;
    border-bottom: 1px solid gray !important;
    padding: 1%;
    text-align: center;
    vertical-align: middle;
  }

  .modal_term_btn_over {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .modal_term_btn_over button {
    border: none;
    border-radius: 10px;
    background: #e5e5e5;
    font-size: 14px;
    width: 150px;
    height: 30px;
    cursor: pointer;
    outline: none;
  }
  .modal_term_btn_over a {
    margin-right: 3%;
  }

  .modal_nation {
    width: 100%;
    outline: none;
  }

  .modal_purpose {
    width: 100%;
    outline: none;
    font-size: 14px;
    color: black;
    border-radius: 5px;
    border: 1px solid gray;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .securetravel_insurance_mainbanner {
    width: 100%;
  }
  .securetravel_insurance_mainbanner img {
    width: 100%;
    border-radius: 5px;
  }

  .secure_insurance_Link_sec_all {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto 2% auto;
  }

  .secure_insurance_Link_sec_all hr {
    margin-top: 2%;
    margin-bottom: 10%;
  }

  .secure_insurance_Link_sec_temp {
    display: flex;
    flex-direction: row;
  }

  .secure_insurance_Link_sec {
    display: flex;
    flex-direction: column;
    width: 90%;
    min-width: 300px;
    max-width: 700px;
    margin: 5% auto 0 auto;
  }

  .secure_insurance_Link_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .secure_insurance_Link_img {
    width: 30%;
    margin-right: 10%;
    border-radius: 50%;
    border: 1px solid #c7c7c7;
  }

  .secure_insurance_Link_text p {
    margin-bottom: 2%;
    margin-top: 2%;
  }
  .secure_insurance_Link_text1 p {
    font-size: 12px;
    color: rgb(155, 155, 155);
    font-weight: bold;
  }
  .secure_insurance_Link_text2 p {
    font-weight: bold;
    font-size: 12px;
    width: 200px;
  }
  .secure_insurance_Link_text3 p {
    font-size: 12px;
    width: 200px;
    font-weight: bold;
    color: $SubColor;
  }

  .secure_insurance_Link_Link {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    justify-content: right;
    cursor: pointer;
  }
  .secure_insurance_Link_Link img {
    margin-right: 3%;
    width: 12px;
  }
  .secure_insurance_Link_Link p {
    font-size: 12px;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .securetravel_insurance_mainbanner {
    width: 100%;
  }
  .securetravel_insurance_mainbanner img {
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
  }

  .secure_insurance_Link_sec_all {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto 2% auto;
  }
  .secure_insurance_Link_sec_all hr {
    margin-top: 2%;
  }

  .secure_insurance_Link_sec_temp {
    display: flex;
    flex-direction: row;
  }

  .secure_insurance_Link_sec {
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: 400px;
    max-width: 700px;
    margin: 0 auto;
  }

  .secure_insurance_Link_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .secure_insurance_Link_img {
    width: 30%;
    margin-right: 10%;
    border-radius: 50%;
    border: 1px solid #c7c7c7;
  }

  .secure_insurance_Link_text p {
    margin-bottom: 2%;
    margin-top: 2%;
  }
  .secure_insurance_Link_text1 p {
    font-size: 16px;
    color: rgb(155, 155, 155);
    font-weight: bold;
  }
  .secure_insurance_Link_text2 p {
    font-weight: bold;
    font-size: 16px;
    width: 300px;
  }
  .secure_insurance_Link_text3 p {
    font-size: 16px;
    width: 300px;
    font-weight: bold;
    color: $SubColor;
  }

  .secure_insurance_Link_Link {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    justify-content: right;
    cursor: pointer;
  }
  .secure_insurance_Link_Link img {
    margin-right: 3%;
    width: 16px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .securetravel_insurance_mainbanner {
    width: 60%;
    margin: 2% auto 0 auto;
  }
  .securetravel_insurance_mainbanner img {
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
  }

  .secure_insurance_Link_sec_all {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto 2% auto;
  }
  .secure_insurance_Link_sec_all hr {
    margin-top: 2%;
  }

  .secure_insurance_Link_sec_temp {
    display: flex;
    flex-direction: row;
  }

  .secure_insurance_Link_sec {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 700px;
    margin: 0 auto;
  }

  .secure_insurance_Link_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .secure_insurance_Link_img {
    width: 30%;
    margin-right: 10%;
    border-radius: 50%;
    border: 1px solid #c7c7c7;
  }

  .secure_insurance_Link_text p {
    margin-bottom: 2%;
    margin-top: 2%;
  }
  .secure_insurance_Link_text1 p {
    font-size: 16px;
    color: rgb(155, 155, 155);
    font-weight: bold;
  }
  .secure_insurance_Link_text2 p {
    font-weight: bold;
    font-size: 20px;
    width: 400px;
  }
  .secure_insurance_Link_text3 p {
    font-size: 20px;
    width: 400px;
    font-weight: bold;
    color: $SubColor;
  }

  .secure_insurance_Link_Link {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    justify-content: right;
    cursor: pointer;
  }
  .secure_insurance_Link_Link img {
    margin-right: 3%;
    width: 16px;
  }
}

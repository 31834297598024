@media only screen and (min-width: 0px) and (max-width: 430px) {
  .backbutton {
    position: absolute;
    top: 6px;
    left: 5px;
    background-color: transparent;
    border: none;
  }

  .backbutton_img {
    width: 25px;
  }
}

@media only screen and (min-width: 431px) and (max-width: 30000px) {
  .backbutton {
    display: none;
  }
}

@media only screen and (min-width: 0px) and (max-width: 650px) {
  #footer {
    background-color: #f9f9f9;
    width: 100%;
    position: absolute;
    height: 230px;
    bottom: 0;
  }

  .footer_all {
    width: 90%;
    padding: 30px 15px;
    font-size: 12px;
  }

  .footer_logo {
    margin: auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
  }

  .footer_logo a img {
    width: 80px;
  }

  .footer_logo div {
    margin-left: 5%;
    font-size: 10px;
  }
  .footer_logo a {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
  }
  .footer_info_list {
    display: flex;
    flex-direction: column;
  }
  .footer_info_list div {
    display: flex;
    margin-right: 1%;
  }
  .footer_info_list div span {
    margin-bottom: 1%;
  }
  .footer_info_list div span:nth-child(2) {
    font-size: 10px;
  }

  .boldline {
    margin-right: 5px;
    font-size: 11px;
    font-weight: 600;
  }

  .bt_copyright {
    display: flex;
    font-size: 9px;
    margin-top: 15px;
    justify-content: center;
  }
}

@media only screen and (min-width: 651px) and (max-width: 1023px) {
  #footer {
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    height: 170px;
    bottom: 0;
  }
  .footer_all {
    width: 90%;
    padding: 30px 15px;
    font-size: 12px;
  }

  .footer_logo {
    margin: auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
  }

  .footer_logo a img {
    width: 100px;
  }

  .footer_logo div {
    margin-left: 10%;
  }
  .footer_logo a {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
  }
  .footer_info_list {
    display: flex;
    margin-bottom: 2%;
  }
  .footer_info_list div {
    display: flex;
    margin-right: 1%;
  }

  .footer_info_list div span:nth-child(2) {
    font-size: 10px;
  }

  .boldline {
    margin-right: 5px;
    font-size: 11px;
    font-weight: 600;
  }

  .bt_copyright {
    display: flex;
    font-size: 9px;
    margin-top: 15px;
    justify-content: center;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  #footer {
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    height: 190px;
    bottom: 0;
  }

  .footer_all {
    width: 70%;
    padding: 30px 15px;
    font-size: 12px;
  }

  .footer_logo {
    margin: auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
  }

  .footer_logo a img {
    width: 200px;
  }

  .footer_logo div {
    margin-left: 10%;
  }
  .footer_logo a {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
  }
  .footer_info_list {
    display: flex;
    margin-bottom: 2%;
  }
  .footer_info_list div {
    display: flex;
    margin-right: 1%;
  }

  .boldline {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 600;
  }

  .bt_copyright {
    display: flex;
    font-size: 9px;
    margin-top: 15px;
    justify-content: center;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .newmypage_materials_prepare_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .newmypage_materials_prepare_modal_modalContent {
    width: 100%;
    max-width: 500px;
    height: 420px; /* 이 값을 조절하여 적절한 높이를 설정하세요 */
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow-y: scroll; /* 스크롤 설정 */
  }

  .newmypage_materials_prepare_modal_modalContent img {
    width: 90%;
    height: auto; /* 이미지의 원래 비율을 유지하면서 너비에 맞게 높이를 조절합니다 */
    max-height: 400px;
    margin: 0 auto;
    position: relative; /* absolute에서 relative로 변경 */
    object-fit: cover;
  }
  .newmypage_materials_prepare_modal_modalContent p {
    font-size: 14px;
    font-weight: bold;
    color: white;
    width: 90%;
    margin: 2% auto 0 auto;
    max-width: 500px;
    overflow-wrap: break-word;
    line-height: 150%;
  }

  .newmypage_materials_img {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .newmypage_materials_img_sec {
    width: 100px;
    height: 100px;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
  }

  .newmypage_materials_img_big {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
    object-fit: cover;
  }
  .newmypage_materials_img div img:nth-child(2) {
    width: 15%;
  }
  .newmypage_materials_img_null {
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    font-size: 30px;
    color: gray;
  }

  .newmypage_materials_all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .newmypage_materials_subtilte_sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .newmypage_materials_subtilte_sec input {
    width: 100px;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #c2c2c2;
    outline: none;
  }

  .newmypage_materials_subtilte_edit_sec {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: gray;
    width: 80%;
    justify-content: space-between;
  }
  .newmypage_materials_subtilte_edit_sec p {
    cursor: pointer;
  }

  .newmypage_materials_subtilte p:nth-child(1) {
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .newmypage_materials_subtilte_edit {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: gray;
    width: 80%;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .newmypage_materials_prepare_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .newmypage_materials_prepare_modal_modalContent {
    width: 100%;
    max-width: 500px;
    height: 520px; /* 이 값을 조절하여 적절한 높이를 설정하세요 */
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow-y: scroll; /* 스크롤 설정 */
  }

  .newmypage_materials_prepare_modal_modalContent img {
    width: 90%;
    height: auto; /* 이미지의 원래 비율을 유지하면서 너비에 맞게 높이를 조절합니다 */
    max-height: 500px;
    margin: 0 auto;
    position: relative; /* absolute에서 relative로 변경 */
    object-fit: cover;
  }
  .newmypage_materials_prepare_modal_modalContent p {
    font-size: 14px;
    font-weight: bold;
    color: white;
    width: 90%;
    margin: 2% auto 0 auto;
    max-width: 500px;
    overflow-wrap: break-word;
    line-height: 150%;
  }

  .newmypage_materials_img {
    width: 90%;
    margin: 0 auto 5% auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .newmypage_materials_img_sec {
    width: 100px;
    height: 100px;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .newmypage_materials_img_big {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
    object-fit: cover;
  }

  .newmypage_materials_img_null {
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    font-size: 30px;
    color: gray;
  }

  .newmypage_materials_all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .newmypage_materials_subtilte_sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .newmypage_materials_subtilte_sec input {
    width: 100px;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #c2c2c2;
    outline: none;
  }

  .newmypage_materials_subtilte_edit_sec {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: gray;
    width: 100%;
    justify-content: space-between;
  }
  .newmypage_materials_subtilte_edit_sec p {
    cursor: pointer;
  }

  .newmypage_materials_subtilte p:nth-child(1) {
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .newmypage_materials_subtilte_edit {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: gray;
    width: 80%;
    justify-content: space-between;
  }
  .newmypage_materials_subtilte_edit p {
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .newmypage_materials_prepare_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .newmypage_materials_prepare_modal_modalContent {
    width: 100%;
    max-width: 500px;
    height: 520px; /* 이 값을 조절하여 적절한 높이를 설정하세요 */
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow-y: scroll; /* 스크롤 설정 */
  }

  .newmypage_materials_prepare_modal_modalContent img {
    width: 100%;
    height: auto; /* 이미지의 원래 비율을 유지하면서 너비에 맞게 높이를 조절합니다 */
    max-height: 500px;
    margin: 0 auto;
    position: relative; /* absolute에서 relative로 변경 */
    object-fit: cover;
  }
  .newmypage_materials_prepare_modal_modalContent p {
    margin-top: 2%;
    font-size: 14px;
    font-weight: bold;
    color: white;
    max-width: 500px;
    overflow-wrap: break-word;
    line-height: 150%;
  }

  .newmypage_materials_img {
    width: 70%;
    margin: 0 auto 5% auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .newmypage_materials_img_sec {
    width: 150px;
    height: 150px;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .newmypage_materials_img_big {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
    object-fit: cover;
  }

  .newmypage_materials_img_null {
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    font-size: 30px;
    color: gray;
  }

  .newmypage_materials_all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .newmypage_materials_subtilte_sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .newmypage_materials_subtilte_sec input {
    width: 100px;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #c2c2c2;
    outline: none;
  }

  .newmypage_materials_subtilte_edit_sec {
    display: flex;
    flex-direction: row;
    color: gray;
    font-size: 14px;
    width: 100%;
    justify-content: space-between;
  }
  .newmypage_materials_subtilte_edit_sec p {
    cursor: pointer;
  }

  .newmypage_materials_subtilte p:nth-child(1) {
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .newmypage_materials_subtilte_edit {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: gray;
    width: 80%;
    justify-content: space-between;
  }
  .newmypage_materials_subtilte_edit p {
    cursor: pointer;
  }
}

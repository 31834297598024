@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .dom_samsung_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dom_samsung_modal_content {
    background-color: white;
    padding: 20px 5%;
    border-radius: 5px;
    max-width: 90%;
    max-height: 500px;
    margin-top: 15%;
    overflow-y: scroll;
  }

  .dom_samsung_modal_content::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .dom_samsung_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dom_samsung_modal_content {
    background-color: white;
    padding: 20px 5%;
    border-radius: 5px;
    max-width: 80%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .dom_samsung_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dom_samsung_modal_content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 545px;
  }
}

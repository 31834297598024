@media only screen and (min-width: 0px) and (max-width: 510px) {
  .recommend-common-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .recommend-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }

  .recommend-modal-content-input {
    text-align: left;
  }

  .recomment-modal-content-input-detail {
    margin-bottom: 10px;
  }

  .recomment-modal-content-input-detail input {
    border: none;
    background-color: #e3e3e3;
    border-radius: 4px;
    text-align: center;
  }

  .recomment-modal-content-input-detail span {
    font-weight: 600;
  }

  .recommend-modal-content h2 {
    margin: 2.3%;
    font-weight: 800;
    font-size: 21px;
    padding: 0 0 7px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }

  .recommend-modal-button-region {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .recomment-modal-content-input-detail label {
    margin: 10px 0;
  }

  #start-select {
    border: none;
    background: #e3e3e3;
    border-radius: 4px;
    width: 100%;
  }
}

@media only screen and (min-width: 511px) {
  .recommend-common-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .recommend-modal-content {
    background-color: #fff;
    padding: 29px;
    border-radius: 16px;
    width: 511px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }

  .recommend-modal-content-input {
    text-align: left;
  }

  .recomment-modal-content-input-detail {
    margin-bottom: 16px;
  }

  .recomment-modal-content-input-detail input {
    border: none;
    background-color: #e3e3e3;
    border-radius: 4px;
    text-align: center;
  }

  .recomment-modal-content-input-detail span {
    font-weight: 600;
  }

  .recommend-modal-content h2 {
    margin: 2.3%;
    font-weight: 800;
    font-size: 21px;
    padding: 0 0 7px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }

  .recommend-modal-button-region {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .recomment-modal-content-input-detail label {
    margin: 10px 0;
  }

  #start-select {
    border: none;
    background: #e3e3e3;
    border-radius: 4px;
    width: 100%;
    font-weight: 600;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .nmpresigter_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .nmpresigter_content {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 5px;
    width: 90%;
    min-height: 300px;
    max-height: 400px;
    overflow-y: scroll;
  }

  .nmpregister_tripdate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5% auto 5% auto;
  }

  .nmpregister_tripdate input {
    width: 100%;
    outline: none;
    font-size: 14px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    height: 30px;
  }
  .nmpregister_tripdate input:nth-child(1) {
    margin-right: 1%;
  }
  .nmpregister_tripdate input:nth-child(2) {
    margin-left: 1%;
  }

  .nmpregister_triptitle,
  .nmpregister_tripschedule {
    margin-bottom: 5%;
  }

  .nmpregister_triptitle input {
    width: 100%;
    outline: none;
    font-size: 16px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    height: 30px;
  }

  .nmpregister_trip_savebtn {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
  }

  .nmpregister_trip_savebtn button {
    background-color: transparent;
    border: 1px solid $SubColor;
    border-radius: 5px;
    color: $SubColor;
    font-weight: bold;
    padding: 2% 10%;
    font-size: 14px;
    width: 50%;
    margin-right: 1%;
    margin-left: 1%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .nmpresigter_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .nmpresigter_content {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 5px;
    width: 90%;
    max-width: 800px;
    min-height: 300px;
    max-height: 500px;
    overflow-y: scroll;
  }
  .nmpresigter_content div p {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2%;
  }

  .nmpregister_tripdate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5% auto 5% auto;
  }
  .nmpregister_tripdate div {
    width: 45%;
  }
  .nmpregister_tripdate input {
    width: 100%;
    outline: none;
    font-size: 14px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    height: 30px;
  }
  .nmpregister_tripdate input:nth-child(1) {
    margin-right: 1%;
  }
  .nmpregister_tripdate input:nth-child(2) {
    margin-left: 1%;
  }

  .nmpregister_triptitle,
  .nmpregister_tripschedule {
    margin-bottom: 5%;
  }

  .nmpregister_triptitle input {
    width: 100%;
    outline: none;
    font-size: 14px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    height: 30px;
  }

  .nmpregister_trip_savebtn {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
  }

  .nmpregister_trip_savebtn button {
    background-color: transparent;
    border: 1px solid $SubColor;
    border-radius: 5px;
    color: $SubColor;
    font-weight: bold;
    padding: 2% 10%;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .nmpresigter_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .nmpresigter_content {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 5px;
    width: 800px;
    height: 550px;
    overflow-y: scroll;
  }

  .nmpresigter_content div p {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2%;
  }

  .nmpregister_tripdate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5% auto 5% auto;
  }
  .nmpregister_tripdate div {
    width: 45%;
  }
  .nmpregister_tripdate input {
    width: 100%;
    outline: none;
    font-size: 14px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    height: 30px;
  }
  .nmpregister_tripdate input:nth-child(1) {
    margin-right: 1%;
  }
  .nmpregister_tripdate input:nth-child(2) {
    margin-left: 1%;
  }

  .nmpregister_triptitle,
  .nmpregister_tripschedule {
    margin-bottom: 5%;
  }

  .nmpregister_triptitle input {
    width: 100%;
    outline: none;
    font-size: 14px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    height: 30px;
  }

  .nmpregister_trip_savebtn {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
  }

  .nmpregister_trip_savebtn button {
    background-color: transparent;
    border: 1px solid $SubColor;
    border-radius: 5px;
    color: $SubColor;
    font-weight: bold;
    padding: 2% 10%;
    font-size: 14px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 430px) {
  .term_all {
    width: 70%;
    margin: 20% auto;
  }

  .term_all div h1 {
    font-weight: bold;
    font-size: 20px;
    margin-top: 5%;
    margin-bottom: 1%;
  }

  .term_all h1 {
    font-weight: bold;
    font-size: 30px;
    margin-top: 5%;
    margin-bottom: 1%;
  }

  .term_all div p {
    line-height: 150%;
  }

  .terms_footer {
    margin-top: 3%;
    color: gray;
  }

  .terms_sub {
    margin-left: 1%;
  }

  .terms_sub2 {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 30000px) {
  .term_all {
    width: 70%;
    margin: 2% auto;
  }

  .term_all div h1 {
    font-weight: bold;
    font-size: 20px;
    margin-top: 5%;
    margin-bottom: 1%;
  }

  .term_all h1 {
    font-weight: bold;
    font-size: 30px;
    margin-top: 5%;
    margin-bottom: 1%;
  }

  .term_all div p {
    line-height: 150%;
  }

  .terms_footer {
    margin-top: 3%;
    color: gray;
  }

  .terms_sub {
    margin-left: 1%;
  }

  .terms_sub2 {
    margin-left: 2%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 430px) {
  .mobile_navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 50px;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid rgb(209, 209, 209);
  }
  .mobile_navigation_all_button {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 0 auto;
  }

  .mobile_navigation_button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 35px;
  }

  .mobile_navigation_button img {
    width: 25%;
  }
  .mobile_navigation_button p {
    font-size: 8px;
    font-weight: bold;
    color: black;
    margin-top: 2%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 30000px) {
  .mobile_navigation {
    display: none;
  }
}

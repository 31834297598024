@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 699px) {
  .coupon_detail_sec {
    width: 90%;
    margin: 5% auto;
  }
  .coupon_up_sec {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .coupon_main_img img {
    width: 100%;
    overflow: hidden;
    object-fit: contain;
    border-radius: 10px;
  }
  .coupon_main_right {
    margin: 5% auto 0 auto;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .coupon_main_right_title {
    font-size: 20px;
    margin-bottom: 5%;
    font-weight: bold;
  }
  .coupon_main_right_expl {
    font-size: 14px;
    color: gray;
  }
  .coupon_table {
    margin: 10% auto 10% auto;
    width: 100%;
  }
  .coupon_tr td:nth-child(1),
  .coupon_tr2 td:nth-child(1),
  .coupon_tr3 td:nth-child(1) {
    width: 45%;
    font-weight: bold;
  }
  .coupon_tr td:nth-child(2),
  .coupon_tr2 td:nth-child(2),
  .coupon_tr3 td:nth-child(2) {
    width: 55%;
    text-align: right;
    color: gray;
    font-size: 14px;
  }

  .coupon_tr,
  .coupon_tr2,
  .coupon_tr3 {
    height: 50px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }

  .coupon_use_btn_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .coupon_use_btn_sec a {
    width: 100%;
    margin-right: 2%;
  }
  .coupon_use_btn_sec_p {
    font-size: 12px;
    color: gray;
    text-align: right;
    margin: 3% 0 0 auto;
  }
  .coupon_use_btn_sec_p2 {
    font-size: 12px;
    color: $SubColor;
    text-align: right;
    margin: 3% 0 0 auto;
    width: 95px;
  }
  .coupon_page_boundary {
    height: 30px;
    display: flex;
    width: 100%;
    margin: 3% auto;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    color: gray;
  }
  .coupon_page_img {
    width: 90%;

    margin: 0 auto;
  }
  .coupon_page_img img {
    width: 100%;
    max-width: 820px;

    margin: 0 auto;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .coupon_detail_sec {
    width: 90%;
    margin: 5% auto;
  }
  .coupon_up_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .coupon_main_img img {
    width: 100%;
    height: auto;
    overflow: hidden;
    object-fit: contain;
    border-radius: 10px;
  }
  .coupon_main_right {
    margin-left: 5%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .coupon_main_right_title {
    font-size: 24px;
    margin-bottom: 1%;
    font-weight: bold;
  }
  .coupon_main_right_expl {
    font-size: 14px;
    color: gray;
  }
  .coupon_table {
    margin: 5% auto 5% auto;
    width: 100%;
  }

  .coupon_tr td:nth-child(1),
  .coupon_tr2 td:nth-child(1),
  .coupon_tr3 td:nth-child(1) {
    width: 45%;
    font-weight: bold;
    font-size: 14px;
  }
  .coupon_tr td:nth-child(2),
  .coupon_tr2 td:nth-child(2),
  .coupon_tr3 td:nth-child(2) {
    width: 55%;
    text-align: right;
    color: gray;
    font-size: 14px;
    padding: 2%;
  }

  .coupon_tr,
  .coupon_tr2,
  .coupon_tr3 {
    height: 100%;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }
  .coupon_use_btn_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .coupon_use_btn_sec a {
    width: 100%;
    margin-right: 2%;
  }
  .coupon_use_btn_sec_p {
    font-size: 12px;
    color: gray;
    text-align: right;
    margin-top: 3%;
  }
  .coupon_use_btn_sec_p2 {
    font-size: 12px;
    color: $SubColor;
    text-align: right;
    margin: 3% 0 0 auto;
    width: 95px;
    cursor: pointer;
    font-weight: bold;
  }

  .coupon_page_boundary {
    height: 50px;
    display: flex;
    width: 100%;
    margin: 3% auto;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    color: gray;
  }

  .coupon_page_img {
    width: 90%;
    display: flex;
    margin: 0 auto;
  }
  .coupon_page_img img {
    width: 50%;
    max-width: 820px;

    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .coupon_detail_sec {
    width: 50%;
    min-width: 820px;
    max-width: 1200px;
    margin: 5% auto;
  }
  .coupon_up_sec {
    display: flex;
    flex-direction: row;
  }
  .coupon_main_img img {
    width: 400px;
    height: 400px;
    overflow: hidden;
    object-fit: contain;
    border-radius: 10px;
  }
  .coupon_main_right {
    margin-left: 5%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .coupon_main_right_title {
    font-size: 30px;
    margin-bottom: 5%;
    font-weight: bold;
  }
  .coupon_main_right_expl {
    font-size: 16px;
    color: gray;
  }
  .coupon_table {
    margin: 10% auto 10% auto;
    width: 100%;
  }

  .coupon_tr td:nth-child(1),
  .coupon_tr2 td:nth-child(1),
  .coupon_tr3 td:nth-child(1) {
    width: 45%;
    font-weight: bold;
  }
  .coupon_tr td:nth-child(2),
  .coupon_tr2 td:nth-child(2),
  .coupon_tr3 td:nth-child(2) {
    width: 55%;
    text-align: right;
    color: gray;
    font-size: 14px;
  }

  .coupon_tr,
  .coupon_tr2,
  .coupon_tr3 {
    height: 50px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }
  .coupon_use_btn_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .coupon_use_btn_sec a {
    width: 100%;
    margin-right: 2%;
  }
  .coupon_use_btn_sec_p {
    font-size: 14px;
    color: gray;
    text-align: right;
    margin-top: 2%;
  }
  .coupon_use_btn_sec_p2 {
    font-size: 14px;
    color: $SubColor;
    text-align: right;
    margin: 2% 0 0 auto;
    width: 120px;
    font-weight: bold;
    cursor: pointer;
  }

  .coupon_page_boundary {
    height: 50px;
    display: flex;
    width: 100%;
    margin: 2% auto;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    color: gray;
  }
  .coupon_page_img {
    width: 90%;

    margin: 0 auto;
  }
  .coupon_page_img img {
    width: 100%;
    max-width: 820px;

    margin: 0 auto;
  }
}

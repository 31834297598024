@import "./Variables.scss";

.m_color {
  background-color: $MainColor;
  color: white;
  border: none;
  @include button_border_radius;
}
.s_color {
  background-color: $SubColor;
  color: white;
  border: none;
  @include button_border_radius;
}

.t_color {
  background-color: white;
  color: black;
  border: 1px solid $MainColor;
  @include button_border_radius;
}

.x_color {
  background-color: $unuseColor;
  color: black;
  border: none;
  @include button_border_radius;
}

.f_button {
  margin-right: 2%;
}

.pc_button {
  font-size: 16px;
  width: 70px;
  height: 30px;
  font-weight: bold;
}

.tablet_button {
  font-size: 14px;
  width: 70px;
  height: 30px;
  font-weight: bold;
}

.mobile_button {
  font-size: 12px;
  width: 70px;
  height: 20px;
  font-weight: bold;
}

.c_position_button {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
}
.r_position_button {
  position: relative;
  left: 100%;
  transform: translate(-100%, 0);
}
.l_position_button {
  position: relative;
  left: 0;
  transform: translate(0%, 0%);
}

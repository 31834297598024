@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .infoone_mainbanner_sec {
    overflow: hidden;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .infoone_mainbanner_sec {
    overflow: hidden;
    width: 62.5%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .infoone_mainbanner_sec {
    overflow: hidden;
    width: 62%;
    margin: 0 auto;
  }
}

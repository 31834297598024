@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .signpopup_sec {
    background-color: #fff;
    border: none;
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.5);
    height: 215px;
    margin: auto;
    position: absolute;
    width: 300px;
    transform: translate(-50%, -50%);
    top: 20%;
    left: 50%;
  }
  .signpopup_text_sec {
    margin: 5% 5%;
    text-align: center;
    font-size: 12px;
    line-height: 120%;
    word-break: keep-all;
  }
  .signpopup_text_0 {
    font-size: 18px;
    font-weight: bold;
    margin: 7% auto;
  }
  .signpopup_text_1 {
    margin-bottom: 7%;
  }
  .signpopup_text_2 {
    font-size: 14px;
    font-weight: bold;
    color: red;
  }
  .signpopup_btn_sec {
    position: relative;
    display: flex;
    width: 100%;
    height: 58px;
    margin: 0 auto;
    justify-content: center;
    font-size: 13px;
  }
  .signpopup_n_btn {
    font-weight: 300;
    height: 50px;
    background-color: #f7f7f7;
    color: gray;
    position: absolute;
    bottom: 1px;
    left: 0;
    border: 1px solid #e5e5e5;
    width: 150px;
  }
  .signpopup_y_btn {
    background-color: black;
    color: white;
    font-weight: bold;
    border: black;
    position: absolute;
    height: 50px;
    bottom: 1px;
    right: 0;
    width: 150px;
  }
}
@media only screen and (min-width: 431px) and (max-width: 30000px) {
  .signpopup_sec {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    width: 400px;
    height: 200px;
    background-color: white;
    border: none;
    box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.5);
  }
  .signpopup_text_sec {
    margin: 3% 3%;
    text-align: center;
    font-size: 14px;
    line-height: 120%;
  }
  .signpopup_text_0 {
    font-size: 20px;
    font-weight: bold;
    margin: 7% auto;
  }
  .signpopup_text_1 {
    margin-bottom: 3%;
  }
  .signpopup_text_2 {
    font-size: 15px;
    font-weight: bold;
    color: red;
  }
  .signpopup_btn_sec {
    position: relative;
    display: flex;
    width: 100%;
    height: 58px;
    margin: 0 auto;
    justify-content: center;
    font-size: 13px;
  }
  .signpopup_n_btn {
    font-weight: 300;
    height: 50px;
    background-color: #f7f7f7;
    color: gray;
    position: absolute;
    bottom: 1px;
    left: 0;
    border: 1px solid #e5e5e5;
    width: 202px;
  }
  .signpopup_y_btn {
    background-color: black;
    color: white;
    font-weight: bold;
    border: black;
    position: absolute;
    height: 50px;
    bottom: 1px;
    right: 0;
    width: 198px;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .newmypage_schedule_detail_title_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .newmypage_schedule_detail_title_sec p:nth-child(2) {
    font-size: 12px;
    color: gray;
  }

  .newmypage_schedule_detail_title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 3%;
  }

  .newmypage_schedule_detail_content_detail {
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-between;
    font-size: 12px;
    margin: 2% auto;
  }
  .newmypage_schedule_detail_content_detail h3 {
    width: 50%;
    border: 1px solid gray;
    border-radius: 3px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
  }
  .newmypage_schedule_detail_content_detail p {
    width: 100%;
    border: 1px solid gray;
    border-radius: 3px;
    margin: auto;
    height: 25px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 2%;
    margin-bottom: 2%;
  }
  .newmypage_schedule_detail_content_detail div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .newmypage_schedule_detail_button {
    display: flex;
    width: 90%;
    margin: 5% auto 0 auto;
    flex-direction: row;
    justify-content: right;
  }
  .newmypage_schedule_detail_button p {
    font-size: 12px;
    font-weight: bold;
    border: 1px solid $MainColor;
    border-radius: 5px;
    height: 25px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .newmypage_schedule_detail_title_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .newmypage_schedule_detail_title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 3%;
  }
  .newmypage_schedule_detail_title_sec p:nth-child(2) {
    font-size: 12px;
    color: gray;
  }

  .newmypage_schedule_detail_content_detail {
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-between;
    font-size: 12px;
    margin: 2% auto;
  }
  .newmypage_schedule_detail_content_detail h3 {
    width: 50%;
    border: 1px solid gray;
    border-radius: 3px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
  }
  .newmypage_schedule_detail_content_detail p {
    width: 100%;
    border: 1px solid gray;
    border-radius: 3px;
    margin: auto;
    height: 25px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 2%;
    margin-bottom: 2%;
  }
  .newmypage_schedule_detail_content_detail div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .newmypage_schedule_detail_button {
    display: flex;
    width: 90%;
    margin: 5% auto 0 auto;
    flex-direction: row;
    justify-content: right;
  }
  .newmypage_schedule_detail_button p {
    font-size: 12px;
    font-weight: bold;
    border: 1px solid $MainColor;
    border-radius: 5px;
    height: 25px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .newmypage_schedule_detail_title_sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .newmypage_schedule_detail_title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 3%;
  }
  .newmypage_schedule_detail_title_sec p:nth-child(2) {
    font-size: 12px;
    color: gray;
  }

  .newmypage_schedule_detail_content_detail {
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-between;
    font-size: 12px;
    margin: 2% auto;
  }
  .newmypage_schedule_detail_content_detail h3 {
    width: 50%;
    border: 1px solid gray;
    border-radius: 3px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
  }
  .newmypage_schedule_detail_content_detail p {
    width: 100%;
    border: 1px solid gray;
    border-radius: 3px;
    margin: auto;
    height: 25px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 2%;
    margin-bottom: 3%;
  }
  .newmypage_schedule_detail_content_detail div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .newmypage_schedule_detail_button {
    display: flex;
    width: 90%;
    margin: 5% auto 0 auto;
    flex-direction: row;
    justify-content: right;
  }
  .newmypage_schedule_detail_button p {
    font-size: 12px;
    font-weight: bold;
    border: 1px solid $MainColor;
    border-radius: 5px;
    height: 25px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .nmpmaterials_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .nmpmaterials_content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    overflow-y: scroll;
  }

  .nmpmaterials_content div img {
    width: 100%;
  }

  .nmpmaterials_content_color,
  .nmpregister_trip_plusbtn {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .nmpmaterials_content_color_title {
    font-size: 12px;
    font-weight: bold;
  }
  .nmpmaterials_content_color_select {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
  }

  .nmpmaterials_content_textarea {
    width: 100%;
    font-size: 16px;
    color: black;
    border: 1px solid #c7c7c7;

    border-radius: 5px;
    margin-top: 5%;
    overflow-y: scroll;
    padding: 2%;
    outline: none;
    resize: none;
  }

  .nmpmaterials_btnsec {
    display: flex;
    width: 100;
    justify-content: right;
  }
  .nmpmaterials_btnsec button:nth-child(1) {
    margin-right: 3%;
  }
  .nmpmaterials_btnsec button {
    margin-top: 5%;
    border: none;
    border-radius: 15px;
    background-color: $SubColor;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 2% 5%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .nmpmaterials_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .nmpmaterials_content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 70%;
    overflow-y: scroll;
  }

  .nmpmaterials_content div img,
  .nmpmaterials_content div textarea {
    width: 100%;
  }

  .nmpmaterials_content div textarea {
    resize: none;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    padding: 2%;
    font-size: 14px;
    outline: none;
  }

  .nmpmaterials_content_color,
  .nmpregister_trip_plusbtn {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .nmpmaterials_content_color_title {
    font-size: 12px;
    font-weight: bold;
  }
  .nmpmaterials_content_color_select {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
  }

  .nmpmaterials_btnsec {
    display: flex;
    width: 100;
    justify-content: right;
  }
  .nmpmaterials_btnsec button:nth-child(1) {
    margin-right: 3%;
  }
  .nmpmaterials_btnsec button {
    margin-top: 5%;
    border: none;
    border-radius: 15px;
    background-color: $SubColor;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 2% 5%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .nmpmaterials_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .nmpmaterials_content div img,
  .nmpmaterials_content div textarea {
    width: 100%;
  }
  .nmpmaterials_content_color {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .nmpmaterials_content div textarea {
    resize: none;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    padding: 2%;
    font-size: 14px;
    outline: none;
  }
  .nmpmaterials_content_color_title {
    font-size: 12px;
    font-weight: bold;
  }
  .nmpmaterials_content_color_select {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
  }
  .nmpmaterials_content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 40%;
    overflow-y: scroll;
  }

  .nmpmaterials_btnsec {
    display: flex;
    width: 100;
    justify-content: right;
  }
  .nmpmaterials_btnsec button:nth-child(1) {
    margin-right: 3%;
  }
  .nmpmaterials_btnsec button {
    margin-top: 5%;
    border: none;
    border-radius: 15px;
    background-color: $SubColor;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 2% 5%;
    cursor: pointer;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .dom_modal_cautions_sec {
    background-color: #f7f7f7;
    padding: 0 10px 10px 10px;
    margin-top: 3%;
  }
  .dom_modal_check3_text {
    font-size: 14px;
    font-weight: bold;
    margin: 5% 0 2% 0;
  }
  .dom_modal_cautions {
    font-size: 12px;
    margin: 0 auto;
    line-height: 130%;
  }
  .dom_modal_cautions_table {
    width: 100%;
    border: 1px solid gray;
    table-layout: fixed;
  }

  .dom_modal_cautions_thead,
  .dom_modal_cautions_thead tr,
  .dom_modal_cautions_thead th,
  .dom_modal_cautions_tbody,
  .dom_modal_cautions_tbody tr,
  .dom_modal_cautions_tbody td {
    border: 1px solid gray;
    border-bottom: 1px solid gray !important;
    padding: 1%;
    text-align: center;
    vertical-align: middle;
  }

  .dom_modal_cautions_thead th {
    background-color: #e5e5e5;
    font-weight: 500;
    font-size: 10px;
  }

  .dom_modal_cautions_thead th,
  .dom_modal_cautions_tbody td {
    width: 20%;
    /* 이 부분은 총 열의 개수에 따라 조정하면 됩니다. */
  }
  .dom_modal_table {
    width: 100%;
    border: 1px solid gray;
    table-layout: fixed;
  }
  .dom_modal_tr,
  .dom_modal_th,
  .dom_modal_tbody,
  .dom_modal_td {
    border: 1px solid gray;
    border-bottom: 1px solid gray !important;
    padding: 1%;
    text-align: center;
    vertical-align: middle;
  }

  .dom_modal_th {
    background-color: #f7f7f7;
    font-weight: 500;
  }

  .dom_modal_th,
  .dom_modal_td {
    width: 20%;
    /* 이 부분은 총 열의 개수에 따라 조정하면 됩니다. */
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .dom_modal_cautions_sec {
    background-color: #f7f7f7;
    padding: 0 10px 10px 10px;
    margin-top: 3%;
  }
  .dom_modal_check3_text {
    font-size: 14px;
    font-weight: bold;
    margin: 5% 0 2% 0;
  }
  .dom_modal_cautions {
    font-size: 12px;
  }
  .dom_modal_cautions_table {
    width: 100%;
    border: 1px solid gray;
    table-layout: fixed;
  }

  .dom_modal_cautions_thead,
  .dom_modal_cautions_thead tr,
  .dom_modal_cautions_thead th,
  .dom_modal_cautions_tbody,
  .dom_modal_cautions_tbody tr,
  .dom_modal_cautions_tbody td {
    border: 1px solid gray;
    border-bottom: 1px solid gray !important;
    padding: 1%;
    text-align: center;
    vertical-align: middle;
  }

  .dom_modal_cautions_thead th {
    background-color: #e5e5e5;
    font-weight: 500;
  }

  .dom_modal_cautions_thead th,
  .dom_modal_cautions_tbody td {
    width: 20%;
  }
  .dom_modal_table {
    width: 100%;
    border: 1px solid gray;
    table-layout: fixed;
  }
  .dom_modal_tr,
  .dom_modal_th,
  .dom_modal_tbody,
  .dom_modal_td {
    border: 1px solid gray;
    border-bottom: 1px solid gray !important;
    padding: 1%;
    text-align: center;
    vertical-align: middle;
  }
  .dom_modal_th {
    background-color: #f7f7f7;
    font-weight: 500;
  }

  .dom_modal_th,
  .dom_modal_td {
    width: 20%;
    /* 이 부분은 총 열의 개수에 따라 조정하면 됩니다. */
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .dom_modal_cautions_sec {
    background-color: #f7f7f7;
    padding: 0 10px 10px 10px;
    margin-top: 3%;
  }
  .dom_modal_check3_text {
    font-size: 14px;
    font-weight: bold;
    margin: 5% 0 2% 0;
  }
  .dom_modal_cautions {
    font-size: 12px;
    line-height: 130%;
  }
  .dom_modal_cautions_table {
    width: 100%;
    border: 1px solid gray;
    table-layout: fixed;
    border-collapse: collapse;
  }

  .dom_modal_cautions_thead,
  .dom_modal_cautions_thead tr,
  .dom_modal_cautions_thead th,
  .dom_modal_cautions_tbody,
  .dom_modal_cautions_tbody tr,
  .dom_modal_cautions_tbody td {
    border: 1px solid gray;
    border-bottom: 1px solid gray !important;
    padding: 1%;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
  }

  .dom_modal_cautions_thead th {
    background-color: #e5e5e5;
    font-weight: 500;
  }

  .dom_modal_cautions_thead th,
  .dom_modal_cautions_tbody td {
    width: 20%;
    word-break: break-all;
    white-space: normal;
    max-width: 150px;
  }
  .dom_modal_table {
    width: 100%;
    border: 1px solid gray;
    table-layout: fixed;
  }
  .dom_modal_tr,
  .dom_modal_th,
  .dom_modal_tbody,
  .dom_modal_td {
    border: 1px solid gray;
    border-bottom: 1px solid gray !important;
    padding: 1%;
    text-align: center;
    vertical-align: middle;
  }
  .dom_modal_th {
    background-color: #f7f7f7;
    font-weight: 500;
  }

  .dom_modal_th,
  .dom_modal_td {
    width: 20%;
    /* 이 부분은 총 열의 개수에 따라 조정하면 됩니다. */
  }
}

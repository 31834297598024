@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .announ_table {
    border: 1px solid #e5e5e5;
    width: 100%;
  }
  .announ_table_sec p {
    text-align: center;
    margin: 15% auto 10% auto;
    font-size: 12px;
  }
  .announ_tr th {
    text-align: center;
    height: 30px;
    vertical-align: middle;
    border: 1px solid #e5e5e5;
    border-top: 1px solid black;
    background-color: #e5e5e5bd;
    font-weight: bold;
    font-size: 12px;
  }
  .announ_tr th:nth-child(1) {
    width: 5%;
  }
  .announ_tr th:nth-child(2) {
    width: 15%;
  }
  .announ_tr th:nth-child(3) {
    width: 60%;
  }
  .announ_tr th:nth-child(4) {
    width: 20%;
  }

  .announ_tr td {
    vertical-align: middle;
    height: 30px;
    border: 1px solid #e5e5e5;
    font-size: 12px;
  }
  .announ_tr td:nth-child(1),
  .announ_tr td:nth-child(2),
  .announ_tr td:nth-child(4) {
    text-align: center;
  }
  .announ_tr td:nth-child(3) {
    padding-left: 2%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .announ_table {
    border: 1px solid #e5e5e5;
    width: 100%;
  }
  .announ_table_sec p {
    text-align: center;
    margin: 15% auto 10% auto;
    font-size: 12px;
  }

  .announ_tr th {
    text-align: center;
    height: 50px;
    vertical-align: middle;
    border: 1px solid #e5e5e5;
    border-top: 1px solid black;
    background-color: #e5e5e5bd;
    font-weight: bold;
    font-size: 16px;
  }
  .announ_tr th:nth-child(1) {
    width: 5%;
  }
  .announ_tr th:nth-child(2) {
    width: 15%;
  }
  .announ_tr th:nth-child(3) {
    width: 45%;
  }
  .announ_tr th:nth-child(4) {
    width: 15%;
  }

  .announ_tr td {
    vertical-align: middle;
    height: 50px;
    border: 1px solid #e5e5e5;
    font-size: 14px;
  }
  .announ_tr td:nth-child(1),
  .announ_tr td:nth-child(2),
  .announ_tr td:nth-child(4) {
    text-align: center;
  }
  .announ_tr td:nth-child(3) {
    padding-left: 2%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .announ_table {
    border: 1px solid #e5e5e5;
    width: 100%;
  }
  .announ_table_sec p {
    text-align: center;
    margin: 15% auto 10% auto;
    font-size: 16px;
  }
  .announ_tr th {
    text-align: center;
    height: 50px;
    vertical-align: middle;
    border: 1px solid #e5e5e5;
    border-top: 1px solid black;
    background-color: #e5e5e5bd;
    font-weight: bold;
    font-size: 16px;
  }
  .announ_tr th:nth-child(1) {
    width: 5%;
  }
  .announ_tr th:nth-child(2) {
    width: 15%;
  }
  .announ_tr th:nth-child(3) {
    width: 45%;
  }
  .announ_tr th:nth-child(4) {
    width: 15%;
  }

  .announ_tr td {
    vertical-align: middle;
    height: 50px;
    border: 1px solid #e5e5e5;
    font-size: 14px;
  }
  .announ_tr td:nth-child(1),
  .announ_tr td:nth-child(2),
  .announ_tr td:nth-child(4) {
    text-align: center;
  }
  .announ_tr td:nth-child(3) {
    padding-left: 2%;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .keep_detail_sec {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    background-color: #e5e5e5;
    align-items: center;
    padding: 0 1%;
    height: 25px;
  }

  .keep_detail_sec button {
    width: 100%;
    border: none;
    border-radius: 5%;
    height: 25px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e5e5e5;
    font-weight: bold;
    color: black;
  }
  .caution_detail_all {
    position: relative;
    padding: 3%;
  }
  .caution_detail_all h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5%;
  }
  .caution_detail_all table,
  .caution_detail_all tr,
  .caution_detail_all td {
    border: 1px solid gray;
    font-size: 12px;
    padding: 5px;
    line-height: 120%;
  }
  .caution_detail_all tr:nth-child(1) {
    background-color: #e5e5e5;
    text-align: center;
    font-weight: bold;
  }
  .caution_detail_all td:nth-child(1) {
    text-align: center;
  }

  .caution_detail_all h2 {
    margin: 4% auto 4% auto;
    font-weight: bold;
    font-size: 14px;
  }

  .caution_detail_all p {
    line-height: 130%;
    font-size: 12px;
  }
  .caution_detail_closebtn_sec {
    position: absolute;
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    top: -5px;
    right: 0;
  }

  .caution_detail_closebtn {
    width: 100%;
    height: 100%;
  }
  .caution_detail_h1_2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .caution_detail_bottom_closebtn_sec {
    margin-top: 5%;
    display: flex;
    justify-content: center;
  }
  .caution_detail_bottom_closebtn {
    padding: 2% 10%;
    font-size: 14px;
    background-color: $SubColor;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 431px) and (max-width: 30000px) {
  .keep_detail_sec {
    display: flex;
    justify-content: center;
    margin-top: 1%;
    background-color: #e5e5e5;
    align-items: center;
    padding: 0 1%;
  }

  .keep_detail_sec button {
    width: 100%;
    border: none;
    border-radius: 5%;
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e5e5e5;
    font-weight: bold;
  }
  .caution_detail_all {
    position: relative;
    padding: 3%;
  }
  .caution_detail_all h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2%;
  }
  .caution_detail_all table,
  .caution_detail_all tr,
  .caution_detail_all td {
    border: 1px solid gray;
    font-size: 14px;
    padding: 5px;
    line-height: 120%;
  }

  .caution_detail_all tr:nth-child(1) {
    background-color: #e5e5e5;
    text-align: center;
    font-weight: bold;
  }
  .caution_detail_all td:nth-child(1) {
    text-align: center;
  }

  .caution_detail_all h2 {
    margin: 2% auto 1% auto;
    font-weight: bold;
    font-size: 14px;
  }

  .caution_detail_all p {
    line-height: 120%;
    font-size: 14px;
  }
  .caution_detail_closebtn_sec {
    position: absolute;
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    top: -5px;
    right: 0;
  }

  .caution_detail_closebtn {
    width: 100%;
    height: 100%;
  }
  .caution_detail_h1_2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 2%;
    margin-top: 2%;
  }
  .caution_detail_bottom_closebtn_sec {
    margin-top: 3%;
    display: flex;
    justify-content: center;
  }
  .caution_detail_bottom_closebtn {
    padding: 0.5% 4%;
    font-size: 14px;
    background-color: $SubColor;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
}

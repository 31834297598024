@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .register_upload_sec {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .register_upload_sec:hover {
    color: white;
  }
  .register_plus_img_sec {
    width: 25px;
    margin-bottom: 3%;
  }

  .register_plus_img {
    width: 100%;
  }
  .register_upload_text {
    font-weight: bold;
    color: #278fff;
    font-size: 16px;
  }

  .memory_upload_all {
    width: 100%;
    margin-bottom: 5%;
  }
  .memory_upload_title {
    background-color: #e5e5e5;
    color: black;
    width: 100%;
    padding: 10px 10px 5px 10px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-weight: 500;
  }
  .memory_upload_btn_sec {
    position: relative;
    width: 100%;
    height: 178px;
    background-color: #e5e5e5;
    border: 12px solid #e5e5e5;
    margin-top: -5px;
  }
  .memory_upload_btn {
    border: 1px solid #e5e5e5;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    height: 100%;
  }
  .memory_upload_input {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .memory_preview_sec {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    border-radius: 15px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    cursor: pointer;
  }
  .memory_preview_sec::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .memory_preview_sec:hover::before {
    opacity: 1;
  }

  .memory_preview_sec::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
    background-image: url(https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/maginifier.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0;
    transition: opacity 0.3s ease;
    /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5)); */
  }
  .memory_preview_sec:hover::after {
    opacity: 1;
  }
  .memory_preview_sec::-webkit-scrollbar {
    display: none;
  }

  .memory_preview_image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: white;
    cursor: pointer;
    padding: 2%;
  }
  .memory_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .memory_modal_image {
    max-width: 80%;
    max-height: 80%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .register_upload_sec {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .register_upload_sec:hover {
    color: white;
  }
  .register_plus_img_sec {
    width: 25px;
    margin-bottom: 3%;
  }
  .register_plus_img {
    width: 100%;
  }

  .register_upload_text {
    font-weight: bold;
    color: #278fff;
    font-size: 16px;
  }
  .memory_upload_all {
    width: 100%;
    margin-bottom: 5%;
  }
  .memory_upload_title {
    background-color: #e5e5e5;
    color: black;
    width: 100%;
    padding: 10px 10px 5px 10px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-weight: 500;
  }
  .memory_upload_btn_sec {
    position: relative;
    width: 100%;
    height: 178px;
    background-color: #e5e5e5;
    border: 12px solid #e5e5e5;
    margin-top: -5px;
  }

  .memory_upload_btn {
    border: 1px solid #e5e5e5;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    height: 100%;
  }
  .memory_upload_input {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .memory_preview_sec {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    border-radius: 15px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    cursor: pointer;
  }
  .memory_preview_sec::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .memory_preview_sec:hover::before {
    opacity: 1;
  }

  .memory_preview_sec::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
    background-image: url(https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/maginifier.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0;
    transition: opacity 0.3s ease;
    /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5)); */
  }
  .memory_preview_sec:hover::after {
    opacity: 1;
  }
  .memory_preview_sec::-webkit-scrollbar {
    display: none;
  }

  .memory_preview_image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: white;
    cursor: pointer;
    padding: 2%;
  }
  .memory_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .memory_modal_image {
    max-width: 80%;
    max-height: 80%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .register_upload_sec {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .register_plus_img_sec {
    width: 25px;
    margin-bottom: 3%;
  }

  .register_plus_img {
    width: 100%;
  }
  .register_upload_text {
    font-weight: bold;
    color: #278fff;
    font-size: 16px;
  }
  .memory_upload_all {
    width: 100%;
    margin-bottom: 5%;
  }
  .memory_upload_title {
    background-color: #e5e5e5;
    color: black;
    width: 100%;
    padding: 10px 10px 5px 10px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-weight: 500;
  }
  .memory_upload_btn_sec {
    position: relative;
    width: 100%;
    height: 178px;
    background-color: #e5e5e5;
    border: 12px solid #e5e5e5;
    margin-top: -5px;
  }

  .memory_upload_btn {
    border: 1px solid #e5e5e5;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    height: 100%;
  }
  .memory_upload_input {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .memory_preview_sec {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    border-radius: 15px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    cursor: pointer;
  }
  .memory_preview_sec::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .memory_preview_sec:hover::before {
    opacity: 1;
  }

  .memory_preview_sec::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
    background-image: url(https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/maginifier.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0;
    transition: opacity 0.3s ease;
    /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5)); */
  }
  .memory_preview_sec:hover::after {
    opacity: 1;
  }
  .memory_preview_sec::-webkit-scrollbar {
    display: none;
  }

  .memory_preview_image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: white;
    cursor: pointer;
    padding: 2%;
  }
  .memory_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .memory_modal_image {
    max-width: 80%;
    max-height: 80%;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .newmypage_schedule_summary_card {
    border: 1px solid #b7b7b7;
    border-radius: 5px;
    margin: 0 auto 3% auto;
    padding: 3% 5%;
    width: 90%;
  }

  .newmypage_schedule_summary_card hr {
    margin: 3% auto;
  }

  .newmypage_schedule_titlesec {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .newmypage_schedule_titlesec div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 50%;
    font-size: 12px;
    color: gray;
    align-items: center;
  }
  .newmypage_schedule_titlesec div:nth-child(2) p {
    margin-right: 5%;
  }
  .newmypage_schedule_titlesec div:nth-child(2) img {
    width: 10px;
    height: 10px;
  }
  .newmypage_schedule_plus {
    width: 90%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .newmypage_schedule_summary_card {
    border: 1px solid #b7b7b7;
    border-radius: 5px;
    margin: 0 auto 3% auto;
    padding: 1%;
    width: 60%;
    cursor: pointer;
  }
  .newmypage_schedule_summary_card hr {
    margin: 3% auto;
  }

  .newmypage_schedule_titlesec {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .newmypage_schedule_titlesec div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 50%;
    font-size: 12px;
    color: gray;
    align-items: center;
  }
  .newmypage_schedule_titlesec div:nth-child(2) p {
    margin-right: 5%;
  }
  .newmypage_schedule_titlesec div:nth-child(2) img {
    width: 10px;
    height: 10px;
  }
  .newmypage_schedule_plus {
    width: 60%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .newmypage_schedule_summary_card {
    border: 1px solid #b7b7b7;
    border-radius: 5px;
    margin: 0 auto 3% auto;
    padding: 1%;
    width: 60%;
    cursor: pointer;
  }
  .newmypage_schedule_summary_card hr {
    margin: 3% auto;
  }

  .newmypage_schedule_titlesec {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .newmypage_schedule_titlesec div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 50%;
    font-size: 12px;
    color: gray;
    align-items: center;
  }
  .newmypage_schedule_titlesec div:nth-child(2) p {
    margin-right: 5%;
  }
  .newmypage_schedule_titlesec div:nth-child(2) img {
    width: 10px;
    height: 10px;
  }
  .newmypage_schedule_plus {
    width: 35%;
  }
}

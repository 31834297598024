/* * {
  font-family: 'Noto Sans KR', 'sans-serif' !important;
  font-weight: 600;
} */

td {
  vertical-align: middle !important;
}
@media only screen and (min-width: 0px) and (max-width: 430px) {
  body {
    height: 100%;
  }
  #wrap {
    min-height: 100vh;
    position: relative;
    padding-bottom: 250px;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  body {
    height: 100%;
  }
  #wrap {
    min-height: 100vh;
    position: relative;
    padding-bottom: 230px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  body {
    height: 100%;
  }
  #wrap {
    min-height: 100vh;
    position: relative;
    padding-bottom: 190px;
  }
}
* {
  /* font-family: "IBM Plex Sans KR", sans-serif !important; */
  /* font-family: "Do Hyeon", sans-serif !important; */
  /* font-family: "Gowun Dodum", sans-serif !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; */
  font-family: "PretendardVariable" !important;
}
@font-face {
  font-family: "PretendardVariable";
  src: url("../src/assets/fonts/PretendardVariable.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

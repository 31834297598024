@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .newmypage_user_info {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto 5% auto;
    justify-content: left;
    align-items: center;
  }

  .nmp_user_profilesec {
    position: relative;
    width: 75px;
    height: 65px;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;
    background-color: #f7f7f7;
  }

  .nmp_user_profile {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
  .nmp_user_profile_audit {
    position: absolute;
    bottom: -75%;
    z-index: 1;
    background-color: black;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    color: white;
    align-items: center;
    font-size: 12px;
    border-width: 0;
    padding-top: 2px;
    font-weight: 300;
  }

  .newmypage_profile_name {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-left: 5%;
  }
  .newmypage_profile_name input {
    width: 150px;
    height: 30px;
    font-size: 16px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    outline: none;
  }

  .newmypage_nickname_profile_edit_input {
    margin-left: 5%;
  }

  .newmypage_profile_setting_img {
    width: 30px;
    height: 30px;
  }

  .newmypage_nick_edit_sec {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
    margin-left: 5%;
  }
  .newmypage_nick_edit_sec_p {
    height: 30px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .newmypage_nick_edit_sec_saved {
    display: flex;
    flex-direction: row;
  }
  .newmypage_nickname_profile_edit_sec {
    display: flex;
    flex-direction: row;
  }
  .newmypage_nickname_profile_edit_sec button {
    background-color: transparent;
    border: none;
    font-size: 12px;
    color: gray;
    margin-top: 3%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .newmypage_user_info {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin: 5% auto;
    justify-content: left;
    align-items: center;
  }

  .nmp_user_profilesec {
    position: relative;
    width: 75px;
    height: 65px;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;
    background-color: #f7f7f7;
  }

  .nmp_user_profile {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100%;
  }
  .nmp_user_profile_audit {
    position: absolute;
    bottom: -75%;
    z-index: 1;
    background-color: black;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    color: white;
    align-items: center;
    font-size: 12px;
    border-width: 0;
    padding-top: 2px;
    font-weight: 300;
  }

  .newmypage_profile_name {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-left: 5%;
  }
  .newmypage_profile_name input {
    width: 150px;
    height: 30px;
    font-size: 12px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    outline: none;
  }

  .newmypage_nickname_profile_edit_input {
    margin-left: 5%;
  }

  .newmypage_profile_setting_img {
    width: 30px;
    height: 30px;
  }

  .newmypage_nick_edit_sec {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: center;
    margin-left: 5%;
  }
  .newmypage_nick_edit_sec_p {
    font-size: 16px;
    font-weight: bold;
  }
  .newmypage_nick_edit_sec_p {
    height: 30px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .newmypage_nick_edit_sec_saved {
    display: flex;
    flex-direction: row;
  }
  .newmypage_nickname_profile_edit_sec {
    display: flex;
    flex-direction: row;
  }
  .newmypage_nickname_profile_edit_sec button {
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: gray;
    margin-top: 3%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .newmypage_user_info {
    display: flex;
    flex-direction: row;
    width: 50%;
    margin: 5% auto;
    justify-content: left;
    align-items: center;
  }

  .nmp_user_profilesec {
    position: relative;
    width: 75px;
    height: 75px;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;
    background-color: #f7f7f7;
  }

  .nmp_user_profile {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100%;
  }
  .nmp_user_profile_audit {
    position: absolute;
    bottom: -75%;
    z-index: 1;
    background-color: black;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    color: white;
    align-items: center;
    font-size: 12px;
    border-width: 0;
    padding-top: 2px;
    font-weight: 300;
  }

  .newmypage_profile_name {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-left: 5%;
  }

  .newmypage_profile_name input {
    width: 200px;
    height: 30px;
    font-size: 14px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    outline: none;
  }

  .newmypage_profile_setting_img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .newmypage_nick_edit_sec {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: center;
  }
  .newmypage_nick_edit_sec_p {
    font-size: 20px;
    font-weight: bold;
  }
  .newmypage_nick_edit_sec_p {
    height: 30px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .newmypage_nick_edit_sec_saved {
    display: flex;
    flex-direction: row;
  }
  .newmypage_nickname_profile_edit_sec {
    display: flex;
    flex-direction: row;
  }
  .newmypage_nickname_profile_edit_sec button {
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: gray;
    margin-top: 3%;
    cursor: pointer;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  @keyframes slideUp {
    from {
      bottom: 0;
    }
    to {
      bottom: 50px; // 하단 네비게이션 바의 높이
    }
  }

  .installApp_sec {
    position: fixed; // absolute에서 fixed로 변경
    width: 300px;
    height: 170px;
    bottom: 50px; // 추가
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 10px;
    z-index: 5000; // z-index를 네비게이션 바보다 높게 설정
    animation: slideUp 0.5s ease-out;
  }
  .installApp_img_sec {
    width: 255px;
    height: 65%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin: 0 auto;
  }
  .installApp_img_sec img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin-right: 4%;
  }
  .installApp_text_sec {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    line-height: 120%;
  }
  .installApp_btn_sec {
    width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    margin: 1% auto;
  }
  .installApp_btn_sec button {
    background-color: black;
    border: 1px solid black;
    color: white;
    font-weight: bold;
    font-size: 12px;
    width: 120px;
    height: 35px;
    cursor: pointer;
  }
  .installApp_btn_sec button:nth-child(1) {
    background-color: white;
    color: black;
    font-weight: bold;
  }
}

@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .installApp_sec {
    display: none;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .installApp_sec {
    display: none;
  }
}

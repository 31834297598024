@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 699px) {
  .mypage_coupon_barcode_modal {
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 450px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }

  .mypage_coupon_barcode_modal_ko {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .mypage_coupon_barcode_modal_en {
    text-align: center;
    font-size: 14px;
    margin-top: 1%;
  }
  .mypage_coupon_barcode_modal_en2 {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .mypage_barcode_modal_sec {
    display: flex;
    justify-content: center;
  }

  .mypage_coupon_modal_sec {
    display: flex;
    flex-direction: column;
  }

  .mypage_coupon_original_img {
    width: 70%;
    margin: 5% auto;
  }

  .mypage_barcode_modal_img {
    width: 95%;
    margin: 2% auto 5% auto;
  }

  .mypage_coupon_modal_close {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .mypage_coupon_modal_close button {
    width: 100px;
    color: white;
    background-color: $SubColor;
    border: none;
    border-radius: 15px;
    height: 30px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .mypage_coupon_barcode_modal {
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 450px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }

  .mypage_coupon_barcode_modal_ko {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .mypage_coupon_barcode_modal_en {
    text-align: center;
    font-size: 14px;
    margin-top: 1%;
  }
  .mypage_coupon_barcode_modal_en2 {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .mypage_barcode_modal_sec {
    display: flex;
    justify-content: center;
  }

  .mypage_coupon_modal_sec {
    display: flex;
    flex-direction: column;
  }

  .mypage_coupon_original_img {
    width: 70%;
    margin: 5% auto;
  }

  .mypage_barcode_modal_img {
    margin: 2% auto 5% auto;
    max-width: 328px;
  }

  .mypage_coupon_modal_close {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .mypage_coupon_modal_close button {
    width: 100px;
    color: white;
    background-color: $SubColor;
    border: none;
    border-radius: 15px;
    height: 30px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .mypage_coupon_barcode_modal {
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 450px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }

  .mypage_coupon_barcode_modal_ko {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .mypage_coupon_barcode_modal_en {
    text-align: center;
    font-size: 14px;
    margin-top: 1%;
  }
  .mypage_coupon_barcode_modal_en2 {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .mypage_barcode_modal_sec {
    display: flex;
    justify-content: center;
  }

  .mypage_coupon_modal_sec {
    display: flex;
    flex-direction: column;
  }

  .mypage_coupon_original_img {
    width: 70%;
    margin: 5% auto;
  }

  .mypage_barcode_modal_img {
    margin: 2% auto 5% auto;
    max-width: 328px;
  }

  .mypage_coupon_modal_close {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .mypage_coupon_modal_close button {
    width: 100px;
    color: white;
    background-color: $SubColor;
    border: none;
    border-radius: 15px;
    height: 30px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 430px) {
  .links_detail_boundary {
    height: 5px;
    background-color: #f7f7f7;
  }

  .links_detail_content {
    margin: 0 auto;
  }

  .links_detail_date {
    font-size: 10px;
    color: gray;
    display: flex;
    justify-content: left;
    margin-top: 5%;
    margin-left: 5%;
  }

  .links_detail_title {
    font-size: 16px;
    font-weight: bold;
    width: 90%;
    margin: 4% auto 2% auto;
  }

  .links_detail_subtitle {
    font-size: 11px;
    width: 90%;
    margin: 3% auto 5% auto;
  }

  .links_detail_imgsec {
    display: flex;
    justify-content: center;
  }

  .links_detail_img {
    max-width: 100%;
    max-height: 100%;
  }

  .links_detail_text {
    font-size: 12px;
    line-height: 2;
    width: 90%;
    margin: 3% auto 0 auto;
  }

  .links_detail_ref {
    display: flex;
    justify-content: center;
    font-size: 10px;
    margin: 7% auto 0 auto;
    width: 90%;
    color: rgb(145, 145, 145);
  }

  .links_detail_btnsec {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .links_detail_btn {
    padding: 2% 3%;
    border: 1px solid #278fff;
    border-radius: 15px;
    background-color: transparent;
    font-size: 11px;
    width: 90%;
    color: #278fff;
    font-weight: bold;
  }

  .links_detail_btn:hover {
    padding: 2% 3%;
    border: 1px solid #278fff;
    border-radius: 15px;
    background-color: #278fff;
    color: white;
    font-size: 11px;
    font-weight: bold;
    width: 90%;
  }
}

@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .links_detail_content {
    padding: 0 5%;
    margin: 0 10%;
  }

  .links_detail_date {
    font-size: 10px;
    color: gray;
    display: flex;
    justify-content: right;
    margin-top: 1%;
  }

  .links_detail_title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 5%;
  }

  .links_detail_subtitle {
    font-size: 12px;
    margin-top: 3%;
  }

  .links_detail_imgsec {
    display: flex;
    justify-content: center;
  }

  .links_detail_img {
    max-width: 100%;
    max-height: 100%;
  }

  .links_detail_text {
    margin-top: 5%;
    font-size: 14px;
    line-height: 2;
  }

  .links_detail_ref {
    display: flex;
    justify-content: right;
    font-size: 10px;
    margin-top: 10%;
  }

  .links_detail_btnsec {
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }

  .links_detail_btn {
    padding: 2% 3%;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    background-color: transparent;
    font-size: 12px;
  }

  .links_detail_btn:hover {
    padding: 2% 3%;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    background-color: green;
    color: white;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .links_detail_content {
    padding: 0 5%;
    margin: 0 auto;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .links_detail_date {
    font-size: 10px;
    color: gray;
    display: flex;
    justify-content: right;
  }

  .links_detail_title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 5%;
  }

  .links_detail_subtitle {
    font-size: 14px;
    margin-top: 3%;
  }

  .links_detail_imgsec {
    display: flex;
    justify-content: center;
  }

  .links_detail_img {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 3%;
  }

  .links_detail_text {
    margin-top: 3%;
    font-size: 14px;
    line-height: 2;
  }

  .links_detail_ref {
    display: flex;
    justify-content: right;
    font-size: 10px;
    margin-top: 3%;
  }

  .links_detail_btnsec {
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }

  .links_detail_btn {
    padding: 2% 3%;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    background-color: transparent;
    font-size: 12px;
  }

  .links_detail_btn:hover {
    padding: 2% 3%;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    background-color: green;
    color: white;
    font-size: 12px;
  }
}

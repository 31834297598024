@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .announ_all {
    width: 90%;
    margin: 10% auto 30% auto;
    height: 100vw;
  }
  .announ_btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    display: none;
  }

  .announ_btn button {
    width: 25%;
    padding: 2%;
    font-size: 14px;
  }
  .announ_btn button:nth-child(1),
  .announ_btn button:nth-child(2),
  .announ_btn button:nth-child(3) {
    border-right: none;
  }

  .announ_btn_sec {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5% auto;
  }

  .announ_btn_sec button {
    padding: 5%;
    border: none;
    border-radius: 15px;
    width: 70%;
    background-color: $MainColor;
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin-right: 2%;
    margin-left: 2%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .announ_all {
    width: 70%;
    margin: 5% auto;
  }
  .announ_btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
  }

  .announ_btn button {
    width: 25%;
    padding: 2%;
  }
  .announ_btn button:nth-child(1),
  .announ_btn button:nth-child(2),
  .announ_btn button:nth-child(3) {
    border-right: none;
  }

  .announ_btn_sec {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5% auto;
  }

  .announ_btn_sec button {
    padding: 5%;
    border: none;
    border-radius: 15px;
    width: 40%;
    background-color: $MainColor;
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin-right: 2%;
    margin-left: 2%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .announ_all {
    width: 70%;
    margin: 5% auto;
  }
  .announ_btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
  }

  .announ_btn button {
    width: 25%;
    padding: 2%;
  }
  .announ_btn button:nth-child(1),
  .announ_btn button:nth-child(2),
  .announ_btn button:nth-child(3) {
    border-right: none;
  }

  .announ_btn_sec {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5% auto;
  }

  .announ_btn_sec button {
    padding: 5%;
    border: none;
    border-radius: 15px;
    width: 40%;
    background-color: $MainColor;
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin-right: 2%;
    margin-left: 2%;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 588px) {
  .toggle-sec-all {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom: 3%;
  }
  .toggle-details-text2 {
    font-size: 12px;
    display: flex;
    justify-content: right;
    position: absolute;
    right: 0;
    top: -3vh;
    text-align: right;
    margin-bottom: 2%;
  }
  .term_container {
    border: 1px solid #e5e5e5;
    height: auto;
    max-height: 60px;
    overflow: scroll;
    padding: 4%;
    line-height: 120%;
    font-size: 10px;
    font-weight: 300;
  }

  .term_container::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 589px) and (max-width: 30000px) {
  .toggle-sec-all {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .toggle-details-text2 {
    font-size: 12px;
    display: flex;
    justify-content: right;
    position: absolute;
    right: 0;
    top: -3vh;
    text-align: right;
    margin-bottom: 2%;
  }
  .term_container {
    border: 1px solid #e5e5e5;
    height: 85px;
    overflow: scroll;
    padding: 4%;
    line-height: 120%;
    font-size: 11px;
    font-weight: 300;
  }

  .term_container::-webkit-scrollbar {
    display: none;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .modalx_sec {
    background-color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    padding: 4%;
    position: absolute;
    left: 50%;
    top: 10%;
    width: 90%;
    transform: translate(-50%, -50%);
  }
  .modalx_text_sec {
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    line-height: 120%;
  }
  .modalx_text_0 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 2%;
  }
  .modalx_text_1 {
    margin-bottom: 1%;
  }
  .modalx_text_2 {
    font-size: 15px;
    font-weight: bold;
    color: red;
    margin-bottom: 1%;
  }
  .modalx_btn_sec {
    position: relative;
    display: flex;
    width: 100%;
    height: 58px;
    margin: 0 auto;
    justify-content: center;
    font-size: 13px;
  }
  .modalx_n_btn {
    font-weight: 300;
    height: 50px;
    background-color: #f7f7f7;
    color: gray;
    position: absolute;
    bottom: 1px;
    left: 0;
    border: 1px solid #e5e5e5;
    width: 50%;
  }

  .modalx_y_btn {
    background-color: black;
    color: white;
    font-weight: bold;
    border: black;
    position: absolute;
    height: 50px;
    bottom: 1px;
    right: 0;
    width: 50%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .modalx_sec {
    background-color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    padding: 4%;
    position: absolute;
    left: 50%;
    top: 10%;
    width: 70%;
    transform: translate(-50%, -50%);
  }
  .modalx_text_sec {
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    line-height: 120%;
  }
  .modalx_text_0 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2%;
  }
  .modalx_text_1 {
    margin-bottom: 1%;
  }
  .modalx_text_2 {
    font-size: 15px;
    font-weight: bold;
    color: red;
    margin-bottom: 1%;
  }
  .modalx_btn_sec {
    position: relative;
    display: flex;
    width: 100%;
    height: 58px;
    margin: 0 auto;
    justify-content: center;
    font-size: 13px;
  }
  .modalx_n_btn {
    font-weight: 300;
    height: 50px;
    background-color: #f7f7f7;
    color: gray;
    position: absolute;
    bottom: 1px;
    left: 0;
    border: 1px solid #e5e5e5;
    width: 50%;
  }

  .modalx_y_btn {
    background-color: black;
    color: white;
    font-weight: bold;
    border: black;
    position: absolute;
    height: 50px;
    bottom: 1px;
    right: 0;
    width: 50%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .modalx_sec {
    background-color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    padding: 1%;
    position: absolute;
    left: 50%;
    top: 5%;
    width: 600px;
    transform: translate(-50%, -50%);
  }
  .modalx_text_sec {
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    line-height: 120%;
  }
  .modalx_text_0 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2%;
  }
  .modalx_text_1 {
    margin-bottom: 1%;
  }
  .modalx_text_2 {
    font-size: 15px;
    font-weight: bold;
    color: red;
    margin-bottom: 1%;
  }
  .modalx_btn_sec {
    position: relative;
    display: flex;
    width: 100%;
    height: 58px;
    margin: 0 auto;
    justify-content: center;
    font-size: 13px;
  }
  .modalx_n_btn {
    font-weight: 300;
    height: 50px;
    background-color: #f7f7f7;
    color: gray;
    position: absolute;
    bottom: 1px;
    left: 0;
    border: 1px solid #e5e5e5;
    width: 50%;
  }

  .modalx_y_btn {
    background-color: black;
    color: white;
    font-weight: bold;
    border: black;
    position: absolute;
    height: 50px;
    bottom: 1px;
    right: 0;
    width: 50%;
  }
}

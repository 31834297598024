@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .cert2_step_all {
    width: 350px;
    display: flex;
    flex-direction: column;
    margin: 10% auto;
  }
  .cert2_step_sec {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .cert2_step1,
  .cert2_step2 {
    margin-right: 1%;
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  .cert2_step1 {
    color: $MainColor;
    font-weight: bold;
  }
  .cert2_step3 {
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  .cert2_all {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 1% auto 3% auto;
  }
  .cert2_box {
    width: 350px;
    height: 300px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    border: 1px solid #e7e7e7;
    border-radius: 15px;
  }

  .cert2_box_expl {
    text-align: center;
  }

  .cert2_box_expl1 {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  .cert2_title_sec {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3%;
    text-align: center;
  }

  .cert2_title {
    font-size: 20px;
    font-weight: bold;
  }
  .cert2_title_expl {
    font-size: 12px;
    color: gray;
    text-align: right;
  }

  .cert2_btn {
    font-size: 14px;
    background-color: $MainColor;
    color: white;
    font-weight: bold;
    padding: 15px 50px;
    border: 1px solid transparent;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 300px;
    text-align: center;
  }

  .cert2_btn.loader-visible {
    background-color: transparent;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .cert2_step_all {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin: 10% auto 10% auto;
  }
  .cert2_step_sec {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 1%;
  }
  .cert2_step1,
  .cert2_step2 {
    margin-right: 1%;
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  .cert2_step1 {
    color: $MainColor;
    font-weight: bold;
  }
  .cert2_step3 {
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  .cert2_all {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 1% auto 15% auto;
  }
  .cert2_box {
    width: 400px;
    height: 300px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    border: 1px solid #e7e7e7;
    border-radius: 15px;
  }

  .cert2_box_expl {
    text-align: center;
  }

  .cert2_box_expl1 {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  .cert2_title_sec {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3%;
    text-align: center;
  }

  .cert2_title {
    font-size: 20px;
    font-weight: bold;
  }

  .cert2_title_expl {
    font-size: 12px;
    color: gray;
    text-align: right;
  }

  .cert2_btn {
    font-size: 14px;
    background-color: $MainColor;
    color: white;
    font-weight: bold;
    padding: 15px 50px;
    border: 1px solid transparent;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 300px;
    text-align: center;
    cursor: pointer;
  }

  .cert2_btn.loader-visible {
    background-color: transparent;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .cert2_step_all {
    width: 70%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    margin: 0% auto 10% auto;
  }
  .cert2_step_sec {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 1%;
  }
  .cert2_step1,
  .cert2_step2 {
    margin-right: 1%;
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  .cert2_step1 {
    color: $MainColor;
    font-weight: bold;
  }
  .cert2_step3 {
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  .cert2_all {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 1% auto 15% auto;
  }
  .cert2_box {
    width: 100%;
    height: 300px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    border: 1px solid #e7e7e7;
    border-radius: 15px;
  }

  .cert2_box_expl {
    text-align: center;
  }

  .cert2_box_expl1 {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  .cert2_title_sec {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3%;
    text-align: center;
  }

  .cert2_title {
    font-size: 20px;
    font-weight: bold;
  }

  .cert2_title_expl {
    font-size: 12px;
    color: gray;
    text-align: right;
  }

  .cert2_btn {
    font-size: 14px;
    background-color: $MainColor;
    color: white;
    font-weight: bold;
    padding: 15px 50px;
    border: 1px solid transparent;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 300px;
    text-align: center;
    cursor: pointer;
  }
  .cert2_btn.loader-visible {
    background-color: transparent;
  }
}

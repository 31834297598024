@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .memory_marker_modal {
    position: absolute;
    width: 150px;
    background-color: white;
    border: 1px solid $SubColor;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    color: #4b4b4b;
    cursor: pointer;
  }
  .memory_marker_modal_nodata {
    position: absolute;
    width: 100px;
    background-color: white;
    border: 1px solid $SubColor;
    border-radius: 5px;
    text-align: center;
    color: #4b4b4b;
    cursor: pointer;
  }
  .memory_marker_modal hr {
    margin: 0 auto;
  }

  .memory_marker_schedule_exist {
    display: flex;
    flex-direction: column;
    font-size: 10px;
  }
  .memory_marker_schedule_exist div {
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 12px;
    color: black;
    font-weight: bold;
    padding: 2%;
    align-items: center;
  }
  .memory_marker_schedule_exist div p:nth-child(2) {
    color: gray;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-weight: 300;
    text-align: right;
    margin-left: auto;
    margin-right: 0;
  }
  .memory_marker_schedule_exist div:nth-child(2) {
    padding-bottom: 0;
    font-weight: 300;
  }

  .memory_marker_schedule_title p {
    margin-bottom: 3%;
    font-size: 14px;
  }

  .memory_marker_plus {
    font-size: 10px;
    margin: 5px auto;
  }

  .memory_select {
    color: black;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
  }
  .memory_select_input {
    width: 90%;
    font-size: 16px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .memory_marker_modal {
    position: absolute;
    width: 200px;
    background-color: white;
    border: 1px solid $SubColor;
    border-radius: 5px;
    text-align: center;
    color: #4b4b4b;
    cursor: pointer;
  }
  .memory_marker_modal_nodata {
    position: absolute;
    width: 100px;
    background-color: white;
    border: 1px solid $SubColor;
    border-radius: 5px;
    text-align: center;
    color: #4b4b4b;
    cursor: pointer;
  }
  .memory_marker_modal hr {
    margin: 0 auto;
  }

  .memory_marker_schedule_exist {
    display: flex;
    flex-direction: column;
  }
  .memory_marker_schedule_exist div {
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 12px;
    color: black;
    font-weight: bold;
    padding: 2%;
    align-items: center;
  }
  .memory_marker_schedule_exist div:nth-child(2) {
    padding-bottom: 0;
    font-weight: 300;
  }
  .memory_marker_schedule_exist div p:nth-child(2) {
    color: gray;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-weight: 300;
    text-align: right;
    margin-left: auto;
    margin-right: 0;
  }

  .memory_marker_schedule_title p {
    margin-bottom: 3%;
    font-size: 14px;
  }

  .memory_marker_plus {
    font-size: 12px;
    margin: 5px auto;
    font-weight: bold;
  }

  .memory_select {
    color: black;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
  }
  .memory_select_input {
    width: 90%;
    font-size: 12px;
    margin: 0 auto 5% auto;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .memory_marker_modal {
    position: absolute;
    width: 300px;
    background-color: white;
    border: 1px solid $SubColor;
    border-radius: 5px;
    text-align: center;
    color: #4b4b4b;
    cursor: pointer;
  }
  .memory_marker_modal_nodata {
    position: absolute;
    width: 200px;
    background-color: white;
    border: 1px solid $SubColor;
    border-radius: 5px;
    text-align: center;
    color: #4b4b4b;
    cursor: pointer;
  }
  .memory_marker_modal hr {
    margin: 0 auto;
  }

  .memory_marker_schedule_exist {
    display: flex;
    flex-direction: column;
  }
  .memory_marker_schedule_exist div {
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 14px;
    color: black;
    font-weight: bold;
    padding: 2%;
    align-items: center;
  }
  .memory_marker_schedule_exist div:nth-child(2) {
    padding-bottom: 0;
    font-weight: 300;
  }
  .memory_marker_schedule_exist div p:nth-child(2) {
    color: gray;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-weight: 300;
    text-align: right;
    margin-left: auto;
    margin-right: 0;
  }

  .memory_marker_schedule_title p {
    margin-bottom: 3%;
    font-size: 14px;
  }

  .memory_marker_plus {
    font-size: 14px;
    margin: 5px auto;
    font-weight: bold;
  }

  .memory_select {
    color: black;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    width: 25%;
    margin: 2% auto 0 auto;
  }
  .memory_select_input {
    width: 90%;
    font-size: 14px;
    margin: 0 auto 5% auto;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .event_title,
  .event_title_text,
  .event_fly_image,
  .event_title h1,
  .event_title h2 {
    display: none;
  }

  .event_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
  .event_img {
    border-radius: 20px;
    width: 90%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .event_title {
    margin-top: 5%;
    margin-bottom: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .event_title_text {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  .event_fly_image {
    width: 70%;
    margin-right: 2%;
  }

  .event_title h1 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 1%;
  }

  .event_title h2 {
    font-size: 14px;
    color: black;
    margin-bottom: 10%;
  }
  .event_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
  }
  .event_img {
    border-radius: 20px;
    width: 90%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .event_title {
    margin-top: 5%;
    margin-bottom: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .event_title_text {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  .event_fly_image {
    width: 70%;
    margin-right: 2%;
  }

  .event_title h1 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 1%;
  }

  .event_title h2 {
    font-size: 14px;
    color: black;
    margin-bottom: 10%;
  }
  .event_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
  }
  .event_img {
    border-radius: 20px;
    width: 90%;
    cursor: pointer;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .infoone_ex_sec_all {
    width: 95%;
    margin: 0 auto;
  }
  .infoone_ex_cal_explain {
    font-size: 10px;
    color: gray;
    text-align: center;
  }
  .infoone_FL_Link_sec_all {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 0 auto 5% auto;
  }
  .infoone_FL_Link_sec_all hr {
    margin-top: 2%;
    margin-bottom: 8%;
  }
  .infoone_FL_exchange_sec {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .infoone_FL_exchange {
    margin-bottom: 5%;
  }
  .infoone_FL_exchange_img {
    border-radius: 15px;
    width: 100%;
    cursor: pointer;
  }
  .infoone_FL_sec {
    width: 90%;
    margin: 10% auto 0 auto;
  }
  .infoone_FL_sec_img {
    width: 80%;
    margin: 0 auto 3% auto;
  }
  .infoone_FL_sec_img img {
    width: 100%;
    margin: 0 auto;
    border-radius: 15px;
  }
  .infoone_FL_Link_sec_temp {
    display: flex;
    flex-direction: row;
  }
  .infoone_FL_Link_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .infoone_FL_Link_img {
    width: 25%;
    border-radius: 5px;
    margin-right: 2%;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
  }
  .infoone_FL_Link_text p:nth-child(1) {
    font-weight: bold;
    font-size: 14px;
  }
  .infoone_FL_Link_text p:nth-child(2) {
    margin-top: 10%;
    font-size: 14px;
  }
  .infoone_FL_Link_Link {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .infoone_FL_Link_Link img {
    margin-right: 3%;
    padding: 2%;
    width: 14px;
  }

  .infoone_FL_Link_Link p {
    font-size: 14px;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .infoone_ex_sec_all {
    width: 95%;
    margin: 0 auto;
  }
  .infoone_ex_sec {
    width: 60%;
    margin: 0 auto;
  }
  .infoone_ex_cal_sec {
    width: 100%;
  }
  .infoone_ex_cal_explain {
    margin-top: 3%;
    font-size: 12px;
    color: gray;
    text-align: center;
  }
  .infoone_FL_Link_sec_all {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto 2% auto;
  }
  .infoone_FL_Link_sec_all hr {
    margin-top: 2%;
  }
  .infoone_FL_exchange_sec {
    width: 90%;
    margin: 3% auto;
    display: flex;
    flex-direction: column;
  }
  .infoone_FL_exchange {
    margin-bottom: 5%;
  }
  .infoone_FL_exchange_img {
    border-radius: 15px;
    width: 100%;
    cursor: pointer;
  }
  .infoone_FL_sec {
    width: 60%;
    margin: 5% auto 0 auto;
  }
  .infoone_FL_sec_img {
    width: 95%;
    margin: 0 auto 3% auto;
  }
  .infoone_FL_sec_img img {
    width: 100%;
    margin: 0 auto;
    border-radius: 15px;
    cursor: pointer;
  }
  .infoone_FL_Link_sec_temp {
    display: flex;
    flex-direction: row;
  }
  .infoone_FL_Link_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .infoone_FL_Link_img {
    width: 25%;
    border-radius: 5px;
    margin-right: 2%;
    border: 1px solid #c7c7c7;
    border-radius: 15px;
  }
  .infoone_FL_Link_text p:nth-child(1) {
    font-weight: bold;
    font-size: 16px;
  }
  .infoone_FL_Link_text p:nth-child(2) {
    margin-top: 20%;
    font-size: 16px;
  }
  .infoone_FL_Link_Link {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .infoone_FL_Link_Link img {
    margin-right: 3%;
    width: 16px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .infoone_ex_sec_all {
    width: 95%;
    margin: 0 auto;
  }
  .infoone_ex_sec {
    width: 60%;
    margin: 0 auto;
  }
  .infoone_ex_cal_sec {
    width: 100%;
  }
  .infoone_ex_cal_explain {
    margin-top: 3%;
    font-size: 12px;
    color: gray;
    text-align: center;
  }
  .infoone_FL_Link_sec_all {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto 2% auto;
  }
  .infoone_FL_Link_sec_all hr {
    margin-top: 2%;
  }
  .infoone_FL_exchange_sec {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .infoone_FL_exchange {
    margin-bottom: 5%;
  }
  .infoone_FL_exchange_img {
    border-radius: 15px;
    width: 100%;
    cursor: pointer;
  }
  .infoone_FL_sec {
    width: 60%;
    margin: 3% auto 5% auto;
  }
  .infoone_FL_sec_img {
    width: 50%;
    margin: 0 auto 3% auto;
  }
  .infoone_FL_sec_img img {
    width: 100%;
    margin: 0 auto;
    border-radius: 15px;
    cursor: pointer;
  }
  .infoone_FL_Link_sec_temp {
    display: flex;
    flex-direction: row;
  }
  .infoone_FL_Link_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .infoone_FL_Link_img {
    width: 50%;
    border-radius: 5px;
    margin-right: 10%;
    border: 1px solid #c7c7c7;
    border-radius: 15px;
  }
  .infoone_FL_Link_text p:nth-child(1) {
    font-weight: bold;
    font-size: 20px;
  }
  .infoone_FL_Link_text p:nth-child(2) {
    margin-top: 15%;
    font-size: 16px;
    width: 250px;
  }

  .infoone_FL_Link_Link {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    justify-content: right;
  }
  .infoone_FL_Link_Link img {
    margin-right: 3%;
    width: 16px;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .secure_category_sec {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .secure_all {
    width: 100%;
  }
  .secure_category_back {
    width: 100%;
    background-color: #eeeeef;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .secure_category_sec {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .secure_all {
    width: 100%;
  }
  .secure_category_back {
    width: 40%;
    margin: 2% auto 0 auto;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .secure_category_sec {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .secure_all {
    width: 100%;
  }
  .secure_category_back {
    width: 40%;
    margin: 2% auto 0 auto;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .ex_car_small_boundary {
    height: 5px;
    width: 100%;
    margin: 0 auto 5% auto;
    background-color: #f7f7f7;
  }
  .main_ex_sec {
    width: 90%;
    margin: 5% auto 0 auto;
  }

  .ex_sec_subtitle {
    font-size: 12px;
    color: gray;
  }
  .ex_sec_small_subtitle {
    font-size: 12px;
    color: black;
    width: 75px;
    display: block;
    font-weight: bold;
  }
  .ex_sec_subtitle p {
    width: 90px;
  }

  .ex_container {
    display: flex;
    justify-content: center;
    margin: 0% auto;
  }

  .ex_car_sec {
    width: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    position: relative;
    margin-bottom: 5%;
  }

  .ex_car_all {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .ex_car_select {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .ex_car_first,
  .ex_car_second {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto 5% auto;
    align-items: center;
  }

  .ex_car_third {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    align-items: center;
  }

  .ex_car_nation {
    font-size: 12px;
    font-weight: 300;
    width: 99%;
    color: black;
    background-color: white;
    outline: none;
  }

  .ex_car_input {
    width: 80%;
    height: 35px;
    text-align: left;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid $MainColor;
    border-radius: 5px;
    margin-right: 2%;
    outline: none;
  }

  .ex_car_result {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .ex_car_small_result_text {
    text-align: right;
    color: $MainColor;
    font-size: 14px;
    font-weight: bold;
  }

  .calculate_rate_btn_sec {
    display: flex;

    margin-top: 1%;
  }

  .calculate_rate_btn {
    width: 25%;
    height: 35px;
    background-color: $SubColor;
    border: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    color: white;
  }
  .ex_sec_help {
    position: absolute;
    right: 4%;
    top: 5%;
    width: 15px;
    cursor: pointer;
  }

  .ex_sec_help_text {
    line-height: 130%;
  }
  .styles-module_tooltip__mnnfp {
    z-index: 2;
  }
  .ex_howto {
    position: absolute;
    right: -19px;
    top: -47px;
    font-size: 12px;
    color: gray;
    z-index: 1;
    cursor: pointer;
    width: 61px;
  }
  .css-7jygp-control {
    border-color: $MainColor !important;
    height: 35px;
  }
  .css-9y3u1z-control {
    border-color: $MainColor !important;
    height: 35px;
  }
}

@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .main_ex_sec {
    width: 90%;
    margin: 5% auto;
  }

  .ex_sec_subtitle {
    font-size: 12px;
    margin-bottom: 4%;
    color: gray;
  }

  .ex_container {
    display: flex;
    justify-content: center;
    margin: 0% auto;
  }

  .ex_car_sec {
    width: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
    position: relative;
  }

  .ex_car_all {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .ex_car_select {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .ex_car_first,
  .ex_car_second,
  .ex_car_third {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto 0 auto;
  }

  .ex_car_nation {
    font-size: 14px;
    font-weight: 300;
    width: 95%;
    outline: none;
  }

  .ex_car_input {
    width: 95%;
    height: 35px;
    text-align: left;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid $MainColor;
    border-radius: 5px;
    outline: none;
  }

  .ex_car_result_text {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #2789ff;
    font-size: 14px;
    font-weight: bold;
    margin-top: 2%;
  }

  .calculate_rate_btn_sec {
    display: flex;

    margin-top: 1%;
  }

  .calculate_rate_btn {
    width: 100px;
    height: 35px;
    background-color: $SubColor;
    border: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    color: white;
  }

  .ex_sec_help {
    position: absolute;
    right: 2%;
    top: 8%;
    width: 15px;
    cursor: pointer;
  }

  .ex_sec_help_text {
    line-height: 130%;
  }
  .styles-module_tooltip__mnnfp {
    z-index: 2;
  }
  .ex_howto {
    position: absolute;
    right: 0;
    top: 10%;
    font-size: 14px;
    color: gray;
    font-weight: bold;
    z-index: 1;
    cursor: pointer;
    width: 61px;
  }
  .css-7jygp-control {
    border-color: $MainColor !important;
    height: 35px;
  }
  .css-9y3u1z-control {
    border-color: $MainColor !important;
    height: 35px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .ex_sec_subtitle {
    font-size: 12px;
    margin-bottom: 4%;
    color: gray;
  }

  .ex_container {
    display: flex;
    justify-content: center;
  }

  .ex_car_sec {
    width: 75%;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    position: relative;
  }

  .ex_car_all {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .ex_car_select {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .ex_car_first,
  .ex_car_second,
  .ex_car_third {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto 0 auto;
  }

  .ex_car_nation {
    font-size: 14px;
    font-weight: 300;
    width: 95%;
    outline: none;
  }

  .ex_car_input {
    width: 95%;
    height: 35px;
    text-align: left;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid $MainColor;
    border-radius: 5px;
    outline: none;
  }

  .ex_car_result_text {
    width: 100%;
    display: flex;
    justify-content: center;
    color: $MainColor;
    font-size: 14px;
    font-weight: bold;
    margin-top: 2%;
  }

  .calculate_rate_btn_sec {
    display: flex;

    margin-top: 1%;
  }

  .calculate_rate_btn {
    width: 100px;
    height: 35px;
    background-color: $SubColor;
    border: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    color: white;
  }
  .ex_sec_help {
    position: absolute;
    right: 2%;
    top: -40%;
    cursor: pointer;
  }

  .ex_sec_help_text {
    line-height: 130%;
  }
  .styles-module_tooltip__mnnfp {
    z-index: 2;
  }
  .ex_howto {
    position: absolute;
    right: 0;
    top: 10%;
    font-size: 12px;
    color: gray;
    font-weight: bold;
    z-index: 1;
    cursor: pointer;
    width: 61px;
  }
  .css-7jygp-control {
    border-color: $MainColor !important;
    height: 35px;
  }
  .css-9y3u1z-control {
    border-color: $MainColor !important;
    height: 35px;
  }
}

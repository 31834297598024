@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .secure_category_sec button {
    width: 50%;
    font-size: 12px;
    height: 25px;
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    text-decoration: none;
    color: black;
  }

  .secure_category_button.clicked {
    background-color: $SubColor;
    color: white;
    font-weight: bold;
    border: none;
    text-decoration: none;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .secure_category_sec button {
    width: 50%;
    font-size: 16px;
    height: 30px;
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }

  .secure_category_button.clicked {
    background-color: $SubColor;
    color: white;
    font-weight: bold;
    border: none;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .secure_category_sec button {
    width: 50%;
    font-size: 16px;
    height: 40px;
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }

  .secure_category_button.clicked {
    background-color: $SubColor;
    color: white;
    font-weight: bold;
    border: none;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .warranty_detail_sec {
    display: flex;
    justify-content: center;
    margin: 1% auto;
    width: 100%;
    background-color: #e5e5e5;
    border: none;
    border-radius: 5px;
    height: 25px;
  }
  .warranty_detail_sec button {
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    color: black;
  }
  .warranty_detail_all {
    position: relative;
    padding: 3%;
  }
  .warranty_detail_all h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5%;
  }
  .warranty_detail_all table,
  .warranty_detail_all tr,
  .warranty_detail_all td {
    border: 1px solid gray;
    font-size: 12px;
    padding: 5px;
    line-height: 120%;
  }
  .warranty_detail_all tr:nth-child(1) {
    background-color: #e5e5e5;
    text-align: center;
    font-weight: bold;
  }
  .warranty_detail_all td:nth-child(1) {
    text-align: center;
  }

  .warranty_detail_all h2 {
    margin: 4% auto 4% auto;
    font-weight: bold;
    font-size: 14px;
  }

  .warranty_detail_all p {
    line-height: 130%;
    font-size: 12px;
  }
  .warranty_detail_bottom_closebtn_sec {
    margin-top: 5%;
    display: flex;
    justify-content: center;
  }
  .warranty_detail_bottom_closebtn {
    padding: 2% 10%;
    font-size: 14px;
    background-color: $SubColor;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 431px) and (max-width: 30000px) {
  .warranty_detail_sec {
    display: flex;
    justify-content: center;
    margin: 1% auto;
    width: 100%;
    background-color: #e5e5e5;
    border: none;
    border-radius: 5px;
    height: 25px;
  }
  .warranty_detail_sec button {
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
  }
  .warranty_detail_all {
    position: relative;
    padding: 3%;
  }
  .warranty_detail_all h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2%;
  }
  .warranty_detail_all table,
  .warranty_detail_all tr,
  .warranty_detail_all td {
    border: 1px solid gray;
    font-size: 14px;
    padding: 5px;
    line-height: 120%;
  }

  .warranty_detail_all tr:nth-child(1) {
    background-color: #e5e5e5;
    text-align: center;
    font-weight: bold;
  }
  .warranty_detail_all td:nth-child(1) {
    text-align: center;
  }

  .warranty_detail_all h2 {
    margin: 2% auto 1% auto;
    font-weight: bold;
    font-size: 14px;
  }

  .warranty_detail_all p {
    line-height: 120%;
    font-size: 14px;
  }
  .warranty_detail_bottom_closebtn_sec {
    margin-top: 3%;
    display: flex;
    justify-content: center;
  }
  .warranty_detail_bottom_closebtn {
    padding: 0.5% 4%;
    font-size: 14px;
    background-color: $SubColor;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
}

.left_align {
  text-align: left !important;
}

.font14_600 {
  font-size: 12px;
  font-weight: 600;
}

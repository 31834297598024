@import "./Variables.scss";

@media only screen and (min-width: 0px) and (max-width: 699px) {
  .travelcoupon_backimg_sec {
    width: 95%;
    height: auto;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
  }
  .travelcoupon_backimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .travelcoupon_backimg_sec {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
  }
  .travelcoupon_backimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .travelcoupon_backimg_sec {
    width: 60%;
    height: auto;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 5px;
  }
  .travelcoupon_backimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px;
  }
}

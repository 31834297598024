@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .announ_detail_all {
    width: 90%;
    margin: 20% auto;
  }
  .announ_detail_title h1 {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
  .announ_detail_title_sec {
    width: 100%;
    height: 50px;
    text-align: center;
    align-items: center;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    margin: 5% auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .announ_detail_title_sec p:nth-child(1) {
    width: 15%;
    text-align: left;
    margin-left: 3%;
    font-size: 12px;
  }
  .announ_detail_title_sec p:nth-child(2) {
    width: 60%;
    text-align: left;
    font-size: 12px;
    padding-left: 2%;
  }
  .announ_detail_title_sec p:nth-child(3) {
    width: 25%;
    text-align: right;
    margin-right: 3%;
    font-size: 12px;
  }
  .announ_detail_content {
    padding-bottom: 5%;
    margin-bottom: 5%;
    border-bottom: 1px solid #aeaeae;
    font-size: 12px;
    padding-left: 3%;
  }
  .announ_detail_btn_sec {
    display: flex;
    justify-content: center;
  }
  .announ_detail_btn_sec button {
    padding: 1% 5%;
    background-color: $MainColor;
    color: white;
    border: none;
    border-radius: 15px;
    margin-bottom: 5%;
    font-size: 12px;
  }
  .announ_detail_prev_content {
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    padding: 2% auto;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
  }
  .announ_detail_prev_content p:nth-child(1) {
    font-size: 12px;
    color: gray;
    margin-right: 3%;
  }
  .announ_detail_next_content {
    border-bottom: 1px solid #aeaeae;
    padding: 2% auto;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 12px;
    border-top: 1px solid #aeaeae;
    margin-top: -1px;
    cursor: pointer;
  }
  .announ_detail_next_content p:nth-child(1) {
    font-size: 12px;
    color: gray;
    margin-right: 3%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .announ_detail_all {
    width: 80%;
    margin: 5% auto;
  }
  .announ_detail_title h1 {
    font-size: 18px;
    font-weight: bold;
    color: black;
  }
  .announ_detail_title_sec {
    width: 100%;
    height: 50px;
    text-align: center;
    align-items: center;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    margin: 5% auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .announ_detail_title_sec p:nth-child(1) {
    width: 15%;
    text-align: left;
    margin-left: 3%;
    font-size: 14px;
  }
  .announ_detail_title_sec p:nth-child(2) {
    width: 70%;
    text-align: left;
    font-size: 14px;
  }
  .announ_detail_title_sec p:nth-child(3) {
    width: 15%;
    text-align: right;
    margin-right: 3%;
    font-size: 12px;
  }
  .announ_detail_content {
    padding-bottom: 5%;
    margin-bottom: 5%;
    border-bottom: 1px solid #aeaeae;
    font-size: 14px;
    padding-left: 3%;
  }
  .announ_detail_btn_sec {
    display: flex;
    justify-content: center;
  }
  .announ_detail_btn_sec button {
    padding: 1% 5%;
    background-color: $MainColor;
    color: white;
    border: none;
    border-radius: 15px;
    margin-bottom: 5%;
    font-size: 12px;
  }
  .announ_detail_prev_content {
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    padding: 2% auto;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .announ_detail_prev_content p:nth-child(1) {
    font-size: 12px;
    color: gray;
    margin-right: 3%;
  }
  .announ_detail_next_content {
    border-bottom: 1px solid #aeaeae;
    padding: 2% auto;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 14px;
    border-top: 1px solid #aeaeae;
    margin-top: -1px;
  }
  .announ_detail_next_content p:nth-child(1) {
    font-size: 12px;
    color: gray;
    margin-right: 3%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .announ_detail_all {
    width: 70%;
    margin: 5% auto;
  }

  .announ_detail_title h1 {
    font-size: 24px;
    font-weight: bold;
    color: black;
  }
  .announ_detail_title_sec {
    width: 100%;
    height: 50px;
    text-align: center;
    align-items: center;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    margin: 5% auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .announ_detail_title_sec p:nth-child(1) {
    width: 15%;
    text-align: left;
    margin-left: 3%;
  }
  .announ_detail_title_sec p:nth-child(2) {
    width: 70%;
    text-align: left;
  }
  .announ_detail_title_sec p:nth-child(3) {
    width: 15%;
    text-align: right;
    margin-right: 3%;
    font-size: 14px;
  }
  .announ_detail_content {
    padding-bottom: 5%;
    margin-bottom: 5%;
    border-bottom: 1px solid #aeaeae;
    padding-left: 3%;
  }
  .announ_detail_btn_sec {
    display: flex;
    justify-content: center;
  }
  .announ_detail_btn_sec button {
    padding: 1% 5%;
    background-color: $MainColor;
    color: white;
    border: none;
    border-radius: 15px;
    margin-bottom: 5%;
  }
  .announ_detail_prev_content {
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    padding: 2% auto;
    height: 30px;
    display: flex;
    align-items: center;
  }
  .announ_detail_prev_content p:nth-child(1) {
    font-size: 14px;
    color: gray;
    margin-right: 3%;
  }
  .announ_detail_next_content {
    border-bottom: 1px solid #aeaeae;
    padding: 2% auto;
    height: 30px;
    display: flex;
    align-items: center;
    border-top: 1px solid #aeaeae;
    margin-top: -1px;
  }
  .announ_detail_next_content p:nth-child(1) {
    font-size: 14px;
    color: gray;
    margin-right: 3%;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .jeju_event_detail_title {
    margin: 0% auto 5% auto;
  }

  .jeju_event_detail_title h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2%;
  }

  .jeju_event_detail_title h2 {
    font-size: 14px;
    color: #9f9f9f;
    margin-bottom: 5%;
  }
  .samsung_jeju_number {
    position: absolute;
    bottom: 35.8%;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
  }
  .event_asiana_detail_dombtn {
    position: absolute;
    bottom: 25.9%;
    width: 45%;
    height: 2%;
    cursor: pointer;
    right: 4%;
  }
  .event_asiana_detail_overbtn {
    position: absolute;
    bottom: 25.9%;
    width: 45%;
    height: 2%;
    cursor: pointer;
    left: 4%;
  }
  .event_jeju_btnmes {
    position: absolute;
    bottom: 39.7%;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 12px;
    color: #00b600;
    align-items: first baseline;
  }
  .event_jeju_btnmes p:nth-child(2) {
    font-weight: bold;
    margin: 0 0.5%;
  }
  .event_jeju_btnmes p:nth-child(1),
  .event_jeju_btnmes p:nth-child(3) {
    font-size: 10px;
  }
  .event_jeju_detail_dombtn {
    position: absolute;
    bottom: 37.3%;
    width: 90%;
    height: 2%;
    left: 5%;
    cursor: pointer;
    color: white;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #00b600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  }
  .jeju_modal_close_btn {
    display: flex;
    justify-content: right;
    position: absolute;
    right: 30px;
  }

  .jeju_modal_close_btn button {
    background-color: transparent;
    border: none;
  }
  .jeju_modal_close_img {
    width: 15px;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .jeju_event_detail_title {
    margin: 5% auto;
  }

  .jeju_event_detail_title h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2%;
  }

  .jeju_event_detail_title h2 {
    font-size: 14px;
    color: #9f9f9f;
    margin-bottom: 5%;
  }
  .samsung_jeju_number {
    position: absolute;
    bottom: 35.8%;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
  }
  .event_asiana_detail_dombtn {
    position: absolute;
    bottom: 25.9%;
    width: 45%;
    height: 2%;
    cursor: pointer;
    right: 4%;
  }
  .event_asiana_detail_overbtn {
    position: absolute;
    bottom: 25.9%;
    width: 45%;
    height: 2%;
    cursor: pointer;
    left: 4%;
  }
  .event_jeju_btnmes {
    position: absolute;
    bottom: 39.7%;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 18px;
    color: #00b600;
    align-items: first baseline;
  }
  .event_jeju_btnmes p:nth-child(2) {
    font-weight: bold;
    margin: 0 0.5%;
  }
  .event_jeju_btnmes p:nth-child(1),
  .event_jeju_btnmes p:nth-child(3) {
    font-size: 16px;
  }
  .event_jeju_detail_dombtn {
    position: absolute;
    bottom: 37.3%;
    width: 90%;
    height: 2%;
    left: 5%;
    cursor: pointer;
    color: white;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #00b600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
  }
  .jeju_modal_close_btn {
    display: flex;
    justify-content: right;
  }

  .jeju_modal_close_btn button {
    background-color: transparent;
    border: none;
  }
  .jeju_modal_close_img {
    width: 20px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .jeju_event_detail_title {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .jeju_event_detail_title h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1%;
  }

  .jeju_event_detail_title h2 {
    font-size: 16px;
    color: #9f9f9f;
    margin-bottom: 3%;
  }
  .samsung_jeju_number {
    position: absolute;
    bottom: 35.8%;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
  }
  .event_asiana_detail_dombtn {
    position: absolute;
    bottom: 25.9%;
    width: 45%;
    height: 2%;
    cursor: pointer;
    right: 4%;
  }
  .event_asiana_detail_overbtn {
    position: absolute;
    bottom: 25.9%;
    width: 45%;
    height: 2%;
    cursor: pointer;
    left: 4%;
  }
  .event_jeju_btnmes {
    position: absolute;
    bottom: 39.7%;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 26px;
    color: #00b600;
    align-items: first baseline;
  }
  .event_jeju_btnmes p:nth-child(2) {
    font-weight: bold;
    margin: 0 0.5%;
  }
  .event_jeju_btnmes p:nth-child(1),
  .event_jeju_btnmes p:nth-child(3) {
    font-size: 20px;
  }
  .event_jeju_detail_dombtn {
    position: absolute;
    bottom: 37.3%;
    width: 90%;
    height: 2%;
    left: 5%;
    cursor: pointer;
    color: white;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #00b600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-weight: bold;
  }
  .jeju_modal_close_btn {
    display: flex;
    justify-content: right;
  }

  .jeju_modal_close_btn button {
    background-color: transparent;
    border: none;
  }
  .jeju_modal_close_img {
    width: 20px;
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .announ_selected {
    background-color: white;
    color: $MainColor;
    font-weight: bold;
    border: 1px solid black;
    border-bottom: none;
    border-right: 1px solid black !important;
  }
  .announ_unselected {
    background-color: white;
    color: black;
    font-weight: bold;
    border: 1px solid #e5e5e5;
    border-bottom: 1px solid black;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .announ_selected {
    background-color: white;
    color: $MainColor;
    font-weight: bold;
    border: 1px solid black;
    border-bottom: none;
    border-right: 1px solid black !important;
  }
  .announ_unselected {
    background-color: white;
    color: black;
    font-weight: bold;
    border: 1px solid #e5e5e5;
    border-bottom: 1px solid black;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .announ_selected {
    background-color: white;
    color: $MainColor;
    font-weight: bold;
    border: 1px solid black;
    border-bottom: none;
    border-right: 1px solid black !important;
  }
  .announ_unselected {
    background-color: white;
    color: black;
    font-weight: bold;
    border: 1px solid #e5e5e5;
    border-bottom: 1px solid black;
  }
}

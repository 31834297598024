@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 699px) {
  .mypage-modal-open .mypage-modal-background {
    display: block;
  }

  .mypage-modal-background {
    display: none;
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .mypage_coupon_move {
    font-size: 14px;
    font-weight: 500;
    color: #4b4b4b;
    background-color: transparent;
    border: 1px solid $MainColor;
    border-radius: 5px;
    height: 30px;
    width: 150px;
    margin: 5% auto 5% auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .mypage-modal-open .mypage-modal-background {
    display: block;
  }

  .mypage-modal-background {
    display: none;
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .mypage_coupon_move {
    font-size: 14px;
    font-weight: 500;
    color: #4b4b4b;
    background-color: transparent;
    border: 1px solid $MainColor;
    border-radius: 5px;
    height: 30px;
    width: 150px;
    margin: 5% auto 5% auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .mypage-modal-open .mypage-modal-background {
    display: block;
  }

  .mypage-modal-background {
    display: none;
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .mypage_coupon_img_sec {
    width: 60%;
    margin: 0 auto;
  }
  .mypage_coupon_move {
    font-size: 14px;
    font-weight: 500;
    color: #4b4b4b;
    background-color: transparent;
    border: 1px solid $MainColor;
    border-radius: 5px;
    height: 30px;
    width: 150px;
    margin: 2% auto 2% auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

@import "./Variables.scss";

.slick-slide:focus {
  outline: none !important;
}
.slick-slide {
  outline: none !important;
}
@media only screen and (min-width: 0px) and (max-width: 431px) {
  .slick-dots {
    bottom: 10px !important;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: $MainColor !important;
  }
}
@media only screen and (min-width: 431px) and (max-width: 30000px) {
  .mainbanner {
    width: 90%;
    margin: 0 auto;
  }
  .slick-dots {
    bottom: 10px !important;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: $MainColor !important;
  }
}

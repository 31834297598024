@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .securemap_step_explain {
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    padding-bottom: 2%;
    background-color: #eeeeef;
  }

  .securemap_step_explain div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto 3% auto;
    font-size: 10px;
    align-items: center;
  }
  .securemap_step_explain p:nth-child(1) {
    margin-right: 10%;
    width: 110px;
    font-weight: bold;
  }

  .securemap_step_explain p:nth-child(2) {
    width: 100%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .securemap_step_explain {
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    padding-bottom: 2%;
    margin-bottom: 5%;
    background-color: #eeeeef;
  }

  .securemap_step_explain div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto 3% auto;
    font-size: 14px;
    align-items: center;
  }
  .securemap_step_explain p:nth-child(1) {
    margin-right: 5%;
    width: 150px;
    font-weight: bold;
  }

  .securemap_step_explain p:nth-child(2) {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .securemap_step_explain {
    display: flex;
    flex-direction: column;
    padding-top: 3%;
    padding-bottom: 1%;
    background-color: #eeeeef;
  }

  .securemap_step_explain div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto 2% auto;
    align-items: center;
  }
  .securemap_step_explain p:nth-child(1) {
    margin-right: 5%;
    width: 150px;
    font-weight: bold;
    font-size: 16px;
  }

  .securemap_step_explain p:nth-child(2) {
    width: 100%;
    font-size: 16px;
  }
}

@import "./Variables.scss";

@media only screen and (min-width: 0px) and (max-width: 430px) {
  .signform_step_sec {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 3%;
    margin-top: 5%;
    width: 90%;
  }
  .signform_step1,
  .signform_step2,
  .signform_step4 {
    margin-right: 1%;
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  .signform_step2 {
    color: $MainColor;
    font-weight: bold;
  }
  .signform_step3 {
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  .signform_bg2 {
    background-color: white;
    width: 90%;
    height: 1200px;
    padding: 6% 3% 3% 3%;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    margin-bottom: 5%;
  }

  .signform_title {
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto 2% auto;
    padding: 0 7%;
  }
  .signform_table_area {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 7%;
  }
  .signform_left_table {
    width: 100%;
    border: none;
    height: 300px;
  }
  .signform_input {
    vertical-align: middle !important;
  }
  .signform_subtitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4%;
  }
  .signform_left_input {
    width: 100%;
    font-size: 14px;
    outline: none;
  }
  .signform_left_input::placeholder {
    width: 100%;
    font-size: 14px;
    color: #c9c9c9;
  }
  .signform_right_table {
    width: 100%;
    border: none;
    height: 300px;
  }
  .agr_readonly {
    padding: 5px;
    border: none;
    border-radius: 5px;
    font-weight: 300;
    font-size: 12px;
    width: 100%;
    outline: none;
    background-color: #f7f7f7;
  }
  .agr_sec_all {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
  }

  .agr_sec_all textarea {
    font-size: 12px;
  }
  .agr_sec_all .agr_title1 div {
    border-bottom: 1px solid #e5e5e5;
  }
  .agr_sec_all div div div div textarea {
    height: 100px !important;
  }
  .signformbtn {
    padding: 10px;
    background-color: $MainColor;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 200px;
    margin: 10px auto 0px auto;
    display: flex;
    justify-content: center;
  }

  .signformbtn.loader-visible {
    background-color: transparent;
  }

  .signformbtn_addr {
    padding: 10px;
    background-color: $MainColor;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 138px;
    display: flex;
    justify-content: center;
  }
  .signform_form {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 0 3% !important;
    align-items: center;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .signform_step_sec {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 3%;
    width: 90%;
  }
  .signform_step1,
  .signform_step2,
  .signform_step4 {
    margin-right: 1%;
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  .signform_step2 {
    color: $MainColor;
    font-weight: bold;
  }
  .signform_step3 {
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  .signform_bg2 {
    background-color: white;
    width: 90%;
    height: 100%;
    padding: 6% 3% 3% 3%;
    border: 1px solid #e7e7e7;
    border-radius: 15px;
  }
  .signform_title {
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto 2% auto;
    padding: 0 7%;
  }
  .signform_table_area {
    display: flex;
    flex-direction: row;
    padding: 0 7%;
  }
  .signform_left_table {
    width: 100%;
    border: none;
    margin-right: 5%;
    height: 400px;
  }
  .signform_input {
    vertical-align: middle !important;
  }
  .signform_subtitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4%;
  }
  .signform_left_input {
    width: 100%;
    font-size: 14px;
  }
  .signform_left_input::placeholder {
    width: 100%;
    font-size: 14px;
    color: #c9c9c9;
  }
  .signform_right_table {
    width: 100%;
    border: none;
    margin-left: 5%;
    height: 400px;
  }
  .agr_readonly {
    padding: 5px;
    border: none;
    border-radius: 5px;
    font-weight: 300;
    font-size: 12px;
    width: 100%;
    background-color: #f7f7f7;
  }
  .agr_sec_all {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
  }

  .agr_sec_all textarea {
    font-size: 12px;
  }
  .agr_sec_all .agr_title1 div {
    border-bottom: 1px solid #e5e5e5;
  }
  .agr_sec_all div div div div textarea {
    height: 100px !important;
  }
  .signformbtn {
    padding: 10px;
    background-color: $MainColor;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 200px;
    margin: 5% auto 0px auto;
    display: flex;
    justify-content: center;
  }

  .signformbtn.loader-visible {
    background-color: transparent;
  }

  .signformbtn_addr {
    padding: 10px;
    background-color: $MainColor;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 138px;
    display: flex;
    justify-content: center;
  }

  .signform_form {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 5% 3% !important;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .signform_step_sec {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 3%;
    width: 800px;
  }
  .signform_step1,
  .signform_step2,
  .signform_step4 {
    margin-right: 1%;
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  .signform_step2 {
    color: $MainColor;
    font-weight: bold;
  }

  .signform_step3 {
    font-size: 14px;
    font-weight: 300;
    color: black;
  }
  .signform_bg2 {
    background-color: white;
    width: 800px;
    height: 100%;
    padding: 6% 3% 3% 3%;
    border: 1px solid #e7e7e7;
    border-radius: 15px;
  }
  .signform_title {
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto 2% auto;
    padding: 0 7%;
  }
  .signform_table_area {
    display: flex;
    flex-direction: row;
    padding: 0 7%;
  }
  .signform_left_table {
    width: 100%;
    border: none;
    margin-right: 5%;
    height: 400px;
  }
  .signform_input {
    vertical-align: middle !important;
  }
  .signform_subtitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4%;
  }
  .signform_left_input {
    width: 100%;
    font-size: 14px;
  }
  .signform_left_input::placeholder {
    width: 100%;
    font-size: 14px;
    color: #c9c9c9;
  }
  .signform_right_table {
    width: 100%;
    border: none;
    margin-left: 5%;
    height: 400px;
  }
  .agr_readonly {
    padding: 5px;
    border: none;
    border-radius: 5px;
    font-weight: 300;
    font-size: 12px;
    width: 100%;
    background-color: #f7f7f7;
  }
  .agr_sec_all {
    width: 500px;
    margin: 0 auto;
  }

  .agr_sec_all textarea {
    font-size: 12px;
  }
  .agr_sec_all .agr_title1 div {
    border-bottom: 1px solid #e5e5e5;
  }
  .agr_sec_all div div div div textarea {
    height: 100px !important;
  }
  .signformbtn {
    padding: 10px;
    background-color: $MainColor;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 200px;
    margin: 3% auto 3% auto;
    display: flex;
    justify-content: center;
  }

  .signformbtn.loader-visible {
    background-color: transparent;
  }

  .signformbtn_addr {
    padding: 10px;
    background-color: $MainColor;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 138px;
    display: flex;
    justify-content: center;
  }

  .signform_form {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 5% 3% !important;
    align-items: center;
  }
}

@media only screen and (min-width: 0px) and (max-width: 430px) {
  .loaderrrr {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #278fff;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
    background-color: transparent;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@media only screen and (min-width: 431px) and (max-width: 30000px) {
  .loaderrrr {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #278fff;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
    background-color: transparent;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .infoone_slider_container {
    width: 230%;
    padding: 2% 53% 0% 2%;
  }
  .infoone_slider_container img {
    width: 95%;
    border-radius: 3px;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .infoone_slider_container {
    width: 230%;
    padding: 2% 53% 0% 2%;
  }
  .infoone_slider_container img {
    width: 95%;
    border-radius: 3px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .infoone_slider_container {
    width: 178%;
    padding: 2% 40% 0% 2%;
  }
  .infoone_slider_container img {
    width: 95%;
    border-radius: 3px;
    cursor: pointer;
  }
}

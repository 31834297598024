@import "./Variables.scss";
@media only screen and (min-width: 0px) and (max-width: 430px) {
  .securetravel_whole_map {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    background-color: #a4defb;
  }

  .securemap_step {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    background-color: white;
    height: 30px;
  }
  .securemap_step div {
    width: 20%;
    border: 1px solid #e5e5e5;
    background-color: white;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
  }

  .securemap_step div.clicked {
    background-color: gray;
    color: white;
    font-weight: bold;
    border-radius: 3px;
  }

  .securemap_search_input {
    width: 90%;
    margin: 0 auto;
    font-size: 16px;
    height: 20px;
    border-radius: 3px;
  }

  .secure_emb_sec_title {
    width: 90%;
    margin: 10% auto 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .secure_emb_sec_title_nation {
    font-weight: bold;
    border-bottom: 3px solid $MainColor;
    font-size: 20px;
    width: 80%;
    margin: 0 auto 2% auto;
    text-align: center;
  }
  .secure_emb_sec_title_explain {
    font-size: 14px;
  }

  .secure_emb_sec {
    width: 90%;
    margin: 5% auto 0 auto;
    font-size: 14px;
  }
  .secure_emb_sec .tit {
    font-weight: bold;
  }

  .secure_emb_sec img {
    width: 100%;
    margin: 0 auto;
  }

  .securemap_magazine_card {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 5% auto 0% auto;
    position: relative;
    overflow: hidden;
  }

  .securemap_magazine_card_title {
    font-size: 14px;
    font-weight: bold;
    width: 100%;
  }

  .securemap_magazine_img {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    border-radius: 15px;
    object-fit: cover;
    margin-bottom: 2%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1023px) {
  .securetravel_whole_map {
    width: 70%;
    height: 400px;
    margin: 0 auto;
  }

  .securemap_step {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    width: 60%;
    margin: 0 auto;
    justify-content: center;
    background-color: white;
    height: 40px;
  }
  .securemap_step div {
    width: 25%;
    border: 1px solid #e5e5e5;
    background-color: white;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
  }

  .securemap_step div.clicked {
    background-color: gray;
    color: white;
    font-weight: bold;
    border-radius: 3px;
    cursor: pointer;
  }

  .securemap_search_input {
    width: 90%;
    margin: 0 auto;
    font-size: 12px;
    height: 100%;
    border-radius: 3px;
  }
  .secure_emb_sec_title {
    width: 90%;
    margin: 10% auto 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .secure_emb_sec_title_nation {
    font-weight: bold;
    border-bottom: 3px solid $MainColor;
    font-size: 20px;
    width: 80%;
    margin: 0 auto 2% auto;
    text-align: center;
  }
  .secure_emb_sec_title_explain {
    font-size: 14px;
  }

  .secure_emb_sec {
    width: 90%;
    margin: 5% auto 0 auto;
    font-size: 14px;
  }
  .secure_emb_sec div {
    line-height: 130%;
  }
  .secure_emb_sec .tit {
    font-weight: bold;
  }

  .secure_emb_sec img {
    width: 100%;
    margin: 0 auto;
  }

  .securemap_magazine_card {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }

  .securemap_magazine_card_title {
    font-size: 16px;
    font-weight: bold;
    margin: 1% auto 7% 5%;
    cursor: pointer;
  }

  .securemap_magazine_img {
    width: 100%;
    margin-bottom: 2%;
    cursor: pointer;
    border-radius: 15px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 30000px) {
  .securetravel_whole_map {
    width: 60%;
    height: 600px;
    margin: 0 auto;
  }

  .securemap_step {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    width: 60%;
    margin: 0 auto;
    justify-content: center;
    background-color: white;
    height: 45px;
  }
  .securemap_step div {
    width: 25%;
    border: 1px solid #e5e5e5;
    background-color: white;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
  }

  .securemap_step div.clicked {
    background-color: gray;
    color: white;
    font-weight: bold;
    border-radius: 3px;
    cursor: pointer;
  }

  .securemap_search_input {
    width: 60%;
    margin: 0 auto;
    font-size: 16px;
    height: 100%;
    border-radius: 3px;
  }

  .secure_emb_sec_title {
    width: 90%;
    margin: 2% auto 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .secure_emb_sec_title_nation {
    font-weight: bold;
    border-bottom: 3px solid $MainColor;
    font-size: 20px;
    width: 80%;
    margin: 0 auto 2% auto;
    text-align: center;
  }
  .secure_emb_sec_title_explain {
    font-size: 14px;
  }

  .secure_emb_sec {
    width: 90%;
    margin: 5% auto 0 auto;
    font-size: 14px;
  }
  .secure_emb_sec div {
    line-height: 130%;
  }
  .secure_emb_sec .tit {
    font-weight: bold;
  }

  .secure_emb_sec img {
    width: 100%;
    margin: 0 auto;
  }

  .securemap_magazine_card_all {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }

  .securemap_magazine_card {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    align-items: center;
  }

  .securemap_magazine_card_title {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 70%;
    margin: 2% auto 10% auto;
  }

  .securemap_magazine_img {
    width: 70%;
    margin-bottom: 10%;
    cursor: pointer;
    border-radius: 15px;
  }
}
